import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { VisitModel } from "api/services/visits/models";
import CompleteVisitButton from "./CompleteVisitButton";
import VisitDetails from "./VisitDetails";
import VisitDocuments from "./VisitDocuments";
import EditVisitButton from "./EditVist";
import { usePermissions } from "stores/authentication/helpers";

interface Props {
  visit: VisitModel;
}

const VisitView: React.FC<Props> = ({ visit }) => {
  const canEditVisit = usePermissions().visits.edit;
  return (
    <Box>
      <Box display={"flex"} gap={2}>
        <Box flex={1}>
          <Typography fontWeight="medium">{visit.title}</Typography>
        </Box>
        {canEditVisit && (
          <Box>
            <EditVisitButton visit={visit} />
          </Box>
        )}
      </Box>
      <Divider />
      <Stack spacing={2}>
        <VisitDetails visit={visit} />
        {!visit.completed && <CompleteVisitButton visit={visit} />}
        {visit.completed && <VisitDocuments visit={visit} />}
      </Stack>
    </Box>
  );
};

export default VisitView;
