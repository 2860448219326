import { useQuery } from "@tanstack/react-query";
import semaphore from "api/utils/Semaphore";
import axios from "axios";
import DefaultImage from "assets/images/com-default.png";
import { API_URL } from "redux/actions/types";
import { SlugString } from "types/models";
interface Response {}
const useGetCommunityImage = (communitySlug: SlugString) => {
  return useQuery({
    queryKey: ["get_community_image", communitySlug],
    staleTime: 1000 * 60 * 60,
    enabled: !!communitySlug,
    queryFn: async () => {
      await semaphore.acquire(); // Adquirir un "permiso" del semáforo
      const url = `${API_URL}/community/${communitySlug}/picture/`;
      const { data } = await axios.get(url);
      if (data.error) {
        return {url: DefaultImage};
      }
      semaphore.release(); // Liberar el "permiso" del semáforo
      console.log(data);
      return data;
    },
  });
};

export default useGetCommunityImage;
