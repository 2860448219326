/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Soft UI Dashboard PRO React components
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import SuiDatePicker from "components/SuiDatePicker";
import { addDocumentToTicket } from "redux/actions/tickets";
import { useDispatch, useSelector } from "react-redux";
import { getProvidersByManagement } from "redux/actions/providers";
import { useParams } from "react-router-dom";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { getCommunitiesByUser } from "redux/actions/communities";
import { getDocumentsTags } from "redux/actions/documents";
import { addDocument } from "redux/actions/documents";
import { createCertification } from "redux/actions/maintenance";
import { CircularProgress, Icon } from "@mui/material";
import CreateProvider from "layouts/pages/providers/Create";
import heic2any from "heic2any";

import { compressFile } from "utils";
import { mobileMaxWidth } from "utils";
import FileInput from "components/FileInput";
import MergePDFComponent from "components/MergePDF";
import { useAuthenticationStore } from "stores/authentication";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { useAuthenticate } from "stores/authentication/helpers";

const parseDate = (unix_timestamp) => {
  var date = new Date(unix_timestamp);
  // Hours part from the timestamp
  var hours = date.getHours();
  // Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  // Will display time in 10:30:23 format
  var formattedTime =
    day +
    "/" +
    month +
    "/" +
    year +
    " " +
    hours +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);

  return formattedTime;
};

function Files(props) {
  const dispatch = useDispatch();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadFile, setuploadFile] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileType, setfileType] = useState(
    props.slugType ? props.slugType : ""
  );
  const [currencyType, setcurrencyType] = useState("CLP");
  const [tags, settags] = useState([]);
  const [proveedor, setproveedor] = useState(null);
  const [price, setprice] = useState("0");
  const { slug } = useParams();
  const [community, setcommunity] = useState("");
  const [providers, setproviders] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [description, setdescription] = useState("");
  const communitiesResponse = useGetCommunities();
  const communities = communitiesResponse.data?.map((community) => {
    return { label: community.name, value: community.slug };
  });
  const providersResponse = useSelector(
    (state) => state.providers.getProvidersByManagement
  );
  const documentsTagsResponse = useSelector(
    (state) => state.documents.getDocumentsTags
  );
  const uploadDocumentResponse = useSelector(
    (state) => state.documents.addDocument
  );
  const createProviderResponse = useSelector(
    (state) => state.providers.createProviderByManagement
  );

  const [taxes, settaxes] = useState("");
  const [documentsTags, setdocumentsTags] = useState([]);
  const [preview, setPreview] = useState(null);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [imagesLoadingHEIC, setImagesLoadingHEIC] = useState(false);

  const compressImage = async (file) => {
    // Convertir HEIF/HEIC a JPEG si es necesario
    if (file.type === "image/heic") {
      try {
        file = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.6,
        });
      } catch (error) {
        console.error("Error converting image:", error);
        return;
      }
    }

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const maxWidth = 800;
      const maxHeight = 800;

      let width = img.width;
      let height = img.height;

      // Calculate the width and height, maintaining the aspect ratio.
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      // Get the compressed image and convert blob to File
      canvas.toBlob(
        (blob) => {
          const compressedFile = new File([blob], file.name, {
            type: "image/jpeg",
            lastModified: Date.now(),
          });

          setImages((prevImages) => [...prevImages, compressedFile]);
        },
        "image/jpeg",
        0.7
      );
    };

    img.src = URL.createObjectURL(file);
  };

  const changeHandler = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setImages([]);

    if (fileType == "imagen") {
      if (selectedFiles.length > 0) {
        if (selectedFiles[0].type === "image/heic") {
          setImagesLoadingHEIC(true);
        }
      }
      selectedFiles.forEach((file) => compressImage(file));
    }
    setIsFilePicked(true);
  };
  useEffect(() => {
    let slugManagement =
      useAuthenticationStore.getState().userProfile.management.slug;
    dispatch(getProvidersByManagement(slugManagement));
    dispatch(getDocumentsTags());
    if (slug) {
      setcommunity(slug);
    }
    if (props.communnity) {
      setcommunity(props.communnity);
    }
    if (props.ticket) {
      setcommunity(props.ticket.community.slug);
    }
  }, []);

  useEffect(() => {
    if (createProviderResponse.data) {
      let slugManagement =
        useAuthenticationStore.getState().userProfile.management.slug;
      dispatch(getProvidersByManagement(slugManagement));
    }
  }, [createProviderResponse]);

  useEffect(() => {
    if (uploadDocumentResponse.data) {
      setloading(false);
    }
  }, [uploadDocumentResponse]);

  useEffect(() => {
    if (providersResponse.data !== undefined) {
      let options = providersResponse.data.map((provider) => {
        return {
          label: provider.executive_name || provider.razon_social,
          value: provider.uuid,
        };
      });
      setproviders(options);
    }
  }, [providersResponse]);

  useEffect(() => {
    if (documentsTagsResponse.data !== undefined) {
      let options = documentsTagsResponse.data.map((tag) => {
        return { label: tag.name, value: tag.uuid };
      });
      setdocumentsTags(options);
    }
  }, [documentsTagsResponse]);

  const handleSubmission = async () => {
    setloading(true);
    let tagsArray = JSON.stringify(
      tags.map((tag) => {
        return { name: tag.label };
      })
    );
    // Preparar formData común
    const commonFormData = new FormData();
    commonFormData.append("name", fileName);
    commonFormData.append("service_provider_uuid", proveedor);
    commonFormData.append(
      "price",
      price.replace(/[^\d,]/g, "").replace(",", ".")
    );
    commonFormData.append("type_slug", fileType);
    // Agrega aquí el resto de los campos comunes
    commonFormData.append("status", preview);
    commonFormData.append("tax", taxes);
    commonFormData.append("currency", currencyType);
    commonFormData.append("observations", description);
    commonFormData.append("tags", tagsArray);
    if (props.checklistItemUuid)
      commonFormData.append("checklist_item_uuid", props.checklistItemUuid);
    if (props.areaUuid) commonFormData.append("area_uuid", props.areaUuid);
    if (props.ticket) commonFormData.append("ticket_uuid", props.ticket.uuid);
    if (props.visit) commonFormData.append("visit_uuid", props.visit.uuid);
    if (props.areaUuid) commonFormData.append("area_uuid", props.areaUuid);
    if (props.visitReport)
      commonFormData.append("report_uuid", props.visitReport);
    if (community) commonFormData.append("community_slug", community);
    if (props.community)
      commonFormData.append("community_slug", props.community);
    if (props.maintenance) {
      commonFormData.append("maintenance_uuid", props.maintenance.uuid);
      commonFormData.append("community_slug", props.maintenance.community.slug);
    }
    if (props.user) {
      commonFormData.append("user_email", props.user.email);
    }
    if (selectedDate) commonFormData.append("expires_on", selectedDate);

    // Manejo específico para imágenes
    if (fileType === "imagen") {
      images.forEach((file) => {
        const formData = new FormData();
        for (const [key, value] of commonFormData.entries()) {
          formData.append(key, value);
        }
        formData.append("file", file);
        // Agrega aquí cualquier otro campo específico de imagen
        dispatch(addDocument(formData));
        setuploadFile(!uploadFile);
      });
    } else {
      if (fileType == "certificacion" && props.maintenance) {
        files.forEach((file) => {
          const formData = new FormData();
          for (const [key, value] of commonFormData.entries()) {
            formData.append(key, value);
          }
          formData.append("due_date", selectedDate);
          formData.append("file", file);
          let document = JSON.stringify({
            name: fileName,
            tags: tags.map((tag) => {
              return { name: tag.label };
            }),
          });
          formData.append("document", document);
          dispatch(createCertification(formData));
          setuploadFile(!uploadFile);
        });
      } else {
        // Manejo de otros tipos de archivos
        files.forEach((file) => {
          const formData = new FormData();
          for (const [key, value] of commonFormData.entries()) {
            formData.append(key, value);
          }
          formData.append("file", file);
          // Agrega aquí cualquier otro campo específico de imagen
          dispatch(addDocument(formData));
          setuploadFile(!uploadFile);
        });
      }
    }
  };

  const renderFileInput = () => {
    return (
      <Grid container width="100%" direction="row">
        <Grid item xs={12} p={2} pt={0}>
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Documento
            </SuiTypography>
          </SuiBox>
          <FileInput onChange={changeHandler} files={files} />
        </Grid>

        <Grid item xs={12} p={2} pt={0}>
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Nombre del documento
            </SuiTypography>
          </SuiBox>
          <SuiInput
            size="small"
            type="text"
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            placeholder="Nombre del documento"
          />
        </Grid>
        {!slug && !props.community && !props.ticket && !props.maintenance ? (
          <Grid item xs={12} p={2} pt={0}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Comunidad
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              onChange={(e) => setcommunity(e.value)}
              placeholder="Comunidad"
              value={community}
              options={communities}
            />
          </Grid>
        ) : (
          ""
        )}
        {fileType != "imagen" && (
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={11} p={2} pt={0}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Proveedor
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  size="small"
                  onChange={(e) => setproveedor(e.value)}
                  placeholder="Proveedor"
                  value={proveedor}
                  options={providers}
                />
              </Grid>
              <Grid item xs={1} pt={2} pr={2}>
                <CreateProvider icon={true} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {[
          "certificacion",
          "cotizacion",
          "factura",
          "cheque",
          "comprobante-de-pago",
          "contrato",
          "seguros",
          "contabilidad",
          "rrhh",
          "otro",
        ].includes(fileType) && (
          <Grid item xs={12} p={2} pt={0}>
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Fecha expiración
            </SuiTypography>
            <SuiDatePicker
              style={{ zIndex: 1000000 }}
              onChange={(e) =>
                setSelectedDate(e[0].toISOString().split("T")[0])
              }
            />
          </Grid>
        )}
        <Grid item xs={12} p={2} pt={0}>
          <MergePDFComponent />
        </Grid>
        {(fileType == "cotizacion" ||
          fileType == "factura" ||
          fileType == "cheque" ||
          fileType == "comprobante-de-pago") && (
          <Grid item xs={12} p={2} pt={0}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Impuestos
              </SuiTypography>
              <SuiSelect
                size="small"
                value={taxes}
                onChange={(e) => {
                  settaxes(e.value);
                }}
                placeholder="...."
                options={[
                  { label: "+ IVA", value: "+ IVA" },
                  { label: "+ Impuesto Boleta", value: "+ Impuesto Boleta" },
                  { label: "N/A", value: "" },
                ]}
              />
            </SuiBox>
          </Grid>
        )}
        {fileType == "cotizacion" && (
          <Grid item xs={12} p={2} pt={0}>
            <SuiBox>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Valoración en
              </SuiTypography>
              <SuiSelect
                size="small"
                value={currencyType}
                onChange={(e) => {
                  setcurrencyType(e.value);
                }}
                placeholder="...."
                options={[
                  { label: "CLP", value: "CLP" },
                  { label: "UF", value: "UF" },
                ]}
              />
            </SuiBox>
          </Grid>
        )}
        {(fileType == "cotizacion" ||
          fileType == "factura" ||
          fileType == "cheque" ||
          fileType == "comprobante-de-pago") && (
          <Grid item xs={12} p={2} pt={0}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Precio
              </SuiTypography>
            </SuiBox>
            <SuiInput
              size="small"
              value={price}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/[^\d,]/g, "");
                let lastIsComma = inputValue[inputValue.length - 1] == ",";
                inputValue = inputValue.replace(".", "");
                inputValue = inputValue.replace(",", ".");
                // Convert the input value to a number and format as a currency
                let formattedValue = parseFloat(inputValue).toLocaleString(
                  "es-CL",
                  {
                    useGrouping: true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                );
                // If the last character is a comma, add it back
                if (lastIsComma) {
                  formattedValue += ",";
                }
                // if formatted value is NaN set to 0
                if (inputValue == "") {
                  formattedValue = "";
                }
                // Set the formatted currency value back to the input field
                setprice(formattedValue);
              }}
              placeholder="Precio"
            />
          </Grid>
        )}
        {fileType == "imagen" && (
          <Grid item xs={12}>
            <SuiBox p={2} pt={0}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Antes o Después
              </SuiTypography>
              <SuiSelect
                size="small"
                value={preview}
                onChange={(e) => {
                  setPreview(e.value);
                }}
                placeholder="Antes o Después"
                options={[
                  { label: "Antes", value: "Antes" },
                  { label: "Después", value: "Después" },
                  { label: "Otro", value: "Otro" },
                ]}
              />
            </SuiBox>
          </Grid>
        )}

        <Grid item xs={12} p={2} pt={0}>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Observación
              </SuiTypography>
            </SuiBox>
            <SuiInput
              size="small"
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              placeholder="Observación"
              multiline
              rows={5}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12} p={2} pt={0}>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Etiquetas documento
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              options={documentsTags}
              onInputChange={(e) => {
                setdocumentsTags([...documentsTags, { label: e, value: e }]);
              }}
              onChange={(e) => settags(e)}
              isMulti
              value={tags}
            />
          </SuiBox>
        </Grid>
        <div style={{ textAlign: "center", width: "100%", padding: 10 }}>
          {" "}
          {fileType == "imagen" && files.length != images.length ? (
            <p>
              Comprimiendo imagenes <br />
              {images.length} de {files.length}
              <br />
              <CircularProgress size={12} color="inherit" />
            </p>
          ) : (
            <SuiButton
              display={loading}
              size="small"
              variant="gradient"
              onClick={handleSubmission}
              color="success"
            >
              {loading ? (
                <CircularProgress size={12} color="inherit" />
              ) : (
                "Guardar"
              )}
            </SuiButton>
          )}
        </div>
      </Grid>
    );
  };

  return (
    <SuiBox height={"100%"}>
      <Card
        style={{
          marginBottom: 20,
          overflow: "visible",
          width: "100%",
          height: "100%",
        }}
      >
        <SuiBox position="relative">
          <SuiBox position="absolute" top={0} right={0} p={2}>
            <Icon onClick={props.toggleDrawer("right", false)}>close</Icon>
          </SuiBox>
        </SuiBox>
        <Grid container alignItems={"center"}>
          <SuiTypography component="h6" fontWeight="bold" p={3}>
            Subir documento
          </SuiTypography>
          {!props.fromReport && (
            <Grid item xs={12}>
              <SuiBox px={4} pb={1}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Tipo de documento
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  option={fileType}
                  size="small"
                  isDisabled={props.slugType ? true : false}
                  onChange={(e) => {
                    setfileType(e.value);
                  }}
                  placeholder="Tipo de archivo"
                  options={[
                    { label: "Cotización", value: "cotizacion" },
                    { label: "Certificación", value: "certificacion" },
                    { label: "Cheque", value: "cheque" },
                    { label: "Circular", value: "circular" },
                    {
                      label: "Comprobante de pago",
                      value: "comprobante-de-pago",
                    },
                    { label: "Contabilidad", value: "contabilidad" },
                    { label: "Contrato", value: "contrato" },

                    { label: "Factura", value: "factura" },
                    { label: "Imagen", value: "imagen" },
                    { label: "Informe", value: "informe" },
                    { label: "Legal", value: "legal" },
                    { label: "Seguros", value: "seguros" },
                    { label: "RRHH", value: "rrhh" },
                    { label: "Orden de Trabajo", value: "orden-de-trabajo" },
                    { label: "Otro", value: "otro" },
                  ]}
                  value={fileType}
                />
              </SuiBox>
            </Grid>
          )}
          {fileType && (
            <SuiBox p={2} pt={0}>
              {renderFileInput()}
            </SuiBox>
          )}
        </Grid>
      </Card>
    </SuiBox>
  );
}

export default Files;
