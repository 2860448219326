/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from 'react-router-dom';

// prop-types is library for typechecking of props
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiAvatar from 'components/SuiAvatar';
import SuiButton from 'components/SuiButton';
import { Divider } from '@mui/material';
import TicketModal from 'components/Tickets/TicketDetailModal';

import DataTable from 'examples/Tables/DataTable';
import SuiBadge from 'components/SuiBadge';
import { ticketStatusColor } from 'utils';
import { ticketPriorityColor } from 'utils';
import SuiProgress from 'components/SuiProgress';

const unitsTableData = (tickets) => {
  let columns = [
    { Header: 'Código', accessor: 'code', width: '20%' },
    { Header: 'Comunidad', accessor: 'community', width: '20%' },
    { Header: 'Título', accessor: 'title', width: '30%' },
    { Header: 'Estado', accessor: 'status', width: '10%' },
    { Header: 'Prioridad', accessor: 'priority', width: '6%' },
    { Header: 'Acciones', accessor: 'actions' },
  ];
  if (tickets === undefined) {
    return { columns, rows: [] };
  }

  let rows = tickets.map((ticket, i) => {
    let date = Date.parse(ticket.created);
    return {
      code: ticket.code,
      title: ticket.title,
      status: (
        <SuiBadge
          badgeContent={ticket.status}
          color={ticketStatusColor[ticket.status]}
          size="xs"
          container
        />
      ),
      community: ticket.community && ticket.community.name,
      priority: (
        <SuiBadge
          badgeContent={ticket.priority}
          color={ticketPriorityColor[ticket.priority]}
          size="xs"
          container
        />
      ),
      progress: (
        <SuiProgress
          style={{ width: '100%' }}
          value={parseFloat(ticket.progress) * 100}
          variant="gradient"
          label
        />
      ),
      area: ticket.area ? ticket.area.name : '',
      actions: <TicketModal uuid={ticket.uuid} community={ticket.community} />,
    };
  });
  return { columns, rows };
};

function UserTicketList(props) {
  const [dataTable, setdataTable] = useState(unitsTableData(props.tickets));
  useEffect(() => {
    setdataTable(unitsTableData(props.tickets));
  }, [props.tickets]);

  const renderTickets = props.tickets
    ? props.tickets.map((ticket, i) => (
        <SuiBox key={i} component="li" display="flex" alignItems="center" py={1} mb={1}>
          <SuiBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <SuiTypography variant="button" fontWeight="medium">
              <SuiBox
                width={3}
                height={'20px'}
                mr={1}
                style={{ float: 'left' }}
                bgColor={
                  ticket.priority == 'baja'
                    ? 'success'
                    : ticket.priority == 'media'
                    ? 'info'
                    : ticket.priority == 'alta'
                    ? 'warning'
                    : ticket.priority == 'crítica'
                    ? 'error'
                    : ''
                }
              ></SuiBox>
              {ticket.community ? ticket.community.name : ''} - {ticket.title}
            </SuiTypography>
            <SuiTypography variant="caption" color="text"></SuiTypography>
          </SuiBox>
          <SuiBox ml="auto">
            <TicketModal community={ticket.community} uuid={ticket.uuid} />
          </SuiBox>
          <Divider />
        </SuiBox>
      ))
    : '';

  return (
    <Card sx={{ height: '100%' }}>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {'Tareas'}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2} style={{ maxHeight: '400px', overflow: 'scroll' }}>
        <DataTable table={dataTable} />
      </SuiBox>
    </Card>
  );
}

export default UserTicketList;
