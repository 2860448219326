/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiEditor from 'components/SuiEditor';
import SuiSelect from 'components/SuiSelect';
import Checkbox from '@mui/material/Checkbox';
// NewProduct page components
import FormField from 'layouts/pages/account/components/FormField';
import { useDispatch } from 'react-redux';
import { createUser } from 'redux/actions/users';
import SuiButton from 'components/SuiButton';
import { validadorEmail } from 'utils';
import { validate, clean, format, getCheckDigit } from 'rut.js';
import { useParams } from 'react-router-dom';
import { getPermission } from 'utils';
import { setNotification } from 'redux/actions/communities';
import { getAuthUserProfile } from 'stores/authentication/helpers';
function Form(props) {
  const [editorValue, setEditorValue] = useState(
    '<p>Some initial <strong>bold</strong> text</p><br><br><br>'
  );
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [name, setname] = useState('');
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [rut, setrut] = useState();
  const [password, setpassword] = useState();
  const [groups, setgroups] = useState([3]);
  const [rutValido, setrutValido] = useState(false);
  const [staffRole, setstaffRole] = useState(5);
  const handleSubmit = () => {
    let management = getAuthUserProfile().management.slug;

    if ((rut && !rutValido) || !validadorEmail(email)) {
      let notification = {
        status: 'error',
        message: 'El rut o email no son validos',
        title: 'Error al crear el usuario',
      };
      dispatch(setNotification(notification));
    } else if (!phone) {
      let notification = {
        status: 'error',
        message: 'El teléfono no puede estar en blanco',
        title: 'Error al crear el usuario',
      };
      dispatch(setNotification(notification));
    } else {
      let form = {
        first_name: name,
        email,
        phone: phone.split(' ').join(''),
        rut,
        management,
        password,
      };
      if (props.community) {
        form.community_slug = slug;
        form.groups = [staffRole];
      } else if (props.comite) {
        form.groups = [2];
        form.community_slug = slug;
      } else {
        form.groups = groups;
      }

      dispatch(createUser(form));
    }
  };

  return (
    <SuiBox>
      <SuiTypography variant="h5">Agregar Colaborador</SuiTypography>
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} sm={6} pb={2}>
          <FormField
            type="text"
            label="Nombre"
            onChange={(e) => setname(e.target.value)}
            placeholder="Sofía Antonia Pérez"
          />
          <SuiTypography variant="caption" fontWeight="regular" textColor="text">
            *Este campo es requerido
          </SuiTypography>
        </Grid>
        <Grid item xs={12} sm={6} pb={2}>
          <FormField
            type="text"
            error={!validadorEmail(email)}
            onChange={(e) => setemail(e.target.value)}
            label="Email"
            placeholder="sofia@swappi.cl"
          />
          <SuiTypography variant="caption" fontWeight="regular" textColor="text">
            *Este campo es requerido
          </SuiTypography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormField
            type="text"
            label="Teléfono"
            onChange={(e) => {
              setphone(e.target.value);
            }}
            placeholder="+56991652881"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type="text"
            value={rut}
            onChange={(e) => {
              let formatted = format(e.target.value);
              setrut(formatted);
              setrutValido(validate(formatted));
            }}
            label="Rut"
            error={!rutValido}
            placeholder="11111111-1"
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ height: 'auto' }}>
          <FormField
            type="text"
            label="Contraseña"
            onChange={(e) => setpassword(e.target.value)}
            placeholder="tunombre.swappi"
          />
          <SuiTypography variant="caption" fontWeight="regular" textColor="text">
            *Este campo es requerido
          </SuiTypography>
        </Grid>
        {props.community && (
          <Grid
            item
            xs={12}
            sm={6}
            alignContent="flex-end"
            justifyContent="flex-end"
            alignSelf="flex-end"
          >
            <Grid sx={{ marginTop: 0 }} container>
              <Grid item xs={6}>
                <SuiTypography variant="button">Conserje</SuiTypography>
                <Checkbox ml={2} checked={staffRole === 5} onChange={(e) => setstaffRole(5)} />
              </Grid>
              <Grid item xs={6}>
                <SuiTypography variant="button">Mayordomo</SuiTypography>
                <Checkbox ml={2} checked={staffRole === 6} onChange={(e) => setstaffRole(6)} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {getPermission(['superadmin']) && !props.community && !props.comite && (
          <Grid item xs={12} sm={6}>
            <Grid sx={{ marginTop: 10 }} container>
              <Grid item xs={6}>
                <SuiTypography variant="button">Administrador</SuiTypography>
                <Checkbox ml={2} checked={groups.includes(1)} onChange={(e) => setgroups([1])} />
              </Grid>
              <Grid item xs={6}>
                <SuiTypography variant="button">Supervisor</SuiTypography>
                <Checkbox ml={2} checked={groups.includes(3)} onChange={(e) => setgroups([3])} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <div>
            <ul style={{ fontSize: 10, marginLeft: 12, marginTop: 5 }}>
              <li>Su contraseña no puede asemejarse tanto a su otra información personal.</li>
              <li>Su contraseña debe contener al menos 8 caracteres.</li>
              <li>Su contraseña no puede ser una clave utilizada comúnmente.</li>
              <li>Su contraseña no puede ser completamente numérica.</li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <SuiButton
        onClick={handleSubmit}
        style={{ float: 'right' }}
        color="success"
        variant="gradient"
        size="small"
      >
        <SuiTypography color="white" variant="button">
          Crear
        </SuiTypography>
      </SuiButton>
    </SuiBox>
  );
}

export default Form;
