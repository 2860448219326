/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import { withStyles } from '@material-ui/core/styles';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useSelector, useDispatch } from 'react-redux';
// Soft UI Dashboard PRO React example components
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import ActionsCard from 'examples/Cards/CommunitiesCards/ActionsCard';
import { mobileMaxWidth } from 'utils';
// General page components
import Units from './units';
import Areas from './areas';
import Tickets from 'layouts/pages/tickets/AdminTickets';
import { useEffect, useState } from 'react';
import { getCommunity } from 'redux/actions/communities';
import { useParams } from 'react-router-dom';
import { FcSurvey } from 'react-icons/fc';
import { SiReadthedocs } from 'react-icons/si';
import { BsFillInboxesFill, BsCalendarWeek, BsFillPeopleFill } from 'react-icons/bs';
import Header from 'layouts/pages/profile/components/Header';
import { GiGreenhouse } from 'react-icons/gi';
import Maintenance from 'layouts/pages/maintenance';
import Documents from 'layouts/pages/documents';
import Loading from 'components/Loading';
import Calendar from 'layouts/pages/calendar';
import Teams from 'layouts/pages/admin-team';
import { Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import Breadcrumbs from 'examples/Breadcrumbs';
import SuiTypography from 'components/SuiTypography';
import SuiBadge from 'components/SuiBadge';
import Comite from './comite';
import AdmiVisit from 'layouts/pages/admi-visit';
import CommunityGantt from 'layouts/pages/charts/CommunityGantt';
import AnualPlanifiaction from './anual-planification';
import TicketsManagement from 'layouts/pages/tickets/AdminTickets';
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -15,
    top: 13,
    backgroundColor: '#21d4fd',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    border: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    boxShadow: 'none',
    borderRadius: '0px',
    border: 'none',
    borderBottom: '3px solid #21d4fd',
    backgroundColor: 'transparent',
  },
  tabRoot: {
    textTransform: 'initial',
    overflow: 'visible',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
});

const Page = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const communityResponse = useSelector((state) => state.communities.getCommunity);
  const [selected, setSelected] = useState(0);
  const [community, setcommunity] = useState({});
  const [loading, setloading] = useState(true);
  const [value, setValue] = useState(0);
  let { slug: communitySlug } = useParams();

  useEffect(() => {
    dispatch(getCommunity(communitySlug));
  }, []);

  useEffect(() => {
    dispatch(getCommunity(communitySlug));
    setSelected(0);
  }, [communitySlug]);

  useEffect(() => {
    if (communityResponse.data) {
      setcommunity(communityResponse);
      setloading(false);
    }
  }, [communityResponse]);

  const renderSelected = () => {
    switch (selected) {
      case 0:
        return <TicketsManagement communitySlug={communitySlug} columnsView="communities" />;
      case 1:
        return <Areas selected={selected} value={1} community={community} />;
      case 2:
        return <Maintenance selected={selected} value={2} />;
      case 3:
        return <Documents selected={selected} value={3} />;
      case 4:
        return <Calendar selected={selected} value={4} community={true} />;
      case 5:
        return <AnualPlanifiaction selected={selected} value={5} />;
      case 6:
        return <Teams selected={selected} value={6} community={true} />;
      case 7:
        return <Comite selected={selected} value={7} />;
      case 8:
        return <AdmiVisit selected={selected} value={8} />;
      case 9:
        return <CommunityGantt selected={selected} value={9} />;
      default:
        return <TicketsManagement communitySlug={communitySlug} columnsView="communities" />;
    }
  };
  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  if (loading) return <Loading />;

  return (
    <>
      <SuiBox display="flex" style={{ justifyContent: 'center', paddingTop: 10 }}>
        <SuiBadge
          color="info"
          variant="gradient"
          badgeContent={
            <SuiTypography
              fontWeight="bold"
              textTransform="capitalize"
              variant="h5"
              color={'white'}
              noWrap
            >
              {community.data.community.name}
            </SuiTypography>
          }
        />
      </SuiBox>
      <Tabs
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        value={selected}
        sx={{ marginTop: 5 }}
        onChange={handleChange}
        orientation={window.innerWidth <= mobileMaxWidth ? 'vertical' : 'horizontal'}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label={
            <StyledBadge badgeContent={community.data.community.n_tickets.open} color="secondary">
              Tareas
            </StyledBadge>
          }
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={
            <StyledBadge badgeContent={community.data.community.n_areas} color="secondary">
              Areas y Sistemas
            </StyledBadge>
          }
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={
            <StyledBadge badgeContent={community.data.community.n_maintenances} color="secondary">
              Mantenciones
            </StyledBadge>
          }
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={
            <StyledBadge badgeContent={community.data.community.n_documents} color="secondary">
              Documentos
            </StyledBadge>
          }
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label="Calendario"
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label="Planificación Anual"
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label="Conserjería"
        />
        <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Comité" />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={
            <StyledBadge badgeContent={community.data.community.n_reports} color="secondary">
              Visitas en Terreno
            </StyledBadge>
          }
        />
        <Tab
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label="Carta Gantt"
        />
      </Tabs>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12}>
          <SuiBox my={3}>{renderSelected()}</SuiBox>
        </Grid>
      </Grid>
    </>
  );
};

const CommunityManagement = withStyles(styles)(Page);
export default CommunityManagement;
