import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from "./Form";
import SuiBox from "components/SuiBox";
import Icon from "@mui/material/Icon";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { isPermissionEnabled } from "utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "background-color": "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Create(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {props.comite && isPermissionEnabled("committee-createCommittee") && (
        <div>
          <SuiBox
            bgColor="success"
            borderRadius="50%"
            width="3.625rem"
            height="3.625rem"
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            color="white"
            mx={props.comite ? "" : "auto"}
            onClick={handleOpen}
            variant="gradient"
            sx={{ cursor: "pointer" }}
          >
            <Icon fontSize="small">add</Icon>
          </SuiBox>
          <SuiBox mt={0.75} textAlign={props.comite ? "left" : "center"} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {props.comite ? "Agregar Comité" : "Agregar Colaborador"}
            </SuiTypography>
          </SuiBox>
        </div>
      )}
      {props.community && isPermissionEnabled("concierge-createConcierge") && !props.comite && (
        <div>
          <SuiBox
            bgColor="success"
            borderRadius="50%"
            width="3.625rem"
            height="3.625rem"
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            color="white"
            mx={props.comite ? "" : "auto"}
            onClick={handleOpen}
            variant="gradient"
            sx={{ cursor: "pointer" }}
          >
            <Icon fontSize="small">add</Icon>
          </SuiBox>
          <SuiBox mt={0.75} textAlign={props.comite ? "left" : "center"} lineHeight={1}>
            <SuiTypography variant="button" color="text" fontWeight="regular">
              {props.comite ? "Agregar Comité" : "Agregar Colaborador"}
            </SuiTypography>
          </SuiBox>
        </div>
      )}

      {!props.community &&
        !props.comite &&
        isPermissionEnabled("users-createUser") &&
        !props.comite && (
          <div>
            <SuiBox
              bgColor="success"
              borderRadius="50%"
              width="3.625rem"
              height="3.625rem"
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              color="white"
              mx={props.comite ? "" : "auto"}
              onClick={handleOpen}
              variant="gradient"
              sx={{ cursor: "pointer" }}
            >
              <Icon fontSize="small">add</Icon>
            </SuiBox>
            <SuiBox mt={0.75} textAlign={props.comite ? "left" : "center"} lineHeight={1}>
              <SuiTypography variant="button" color="text" fontWeight="regular">
                {props.comite ? "Agregar Comité" : "Agregar Colaborador"}
              </SuiTypography>
            </SuiBox>
          </div>
        )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={style}>
          <Form close={handleClose} community={props.community} comite={props.comite} />
        </Box>
      </Modal>
    </div>
  );
}
