/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { capitalize } from "utils";
// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiBadge from "components/SuiBadge";
// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import SuiButton, { SuiButtonTs } from "components/SuiButton";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import { getActivityUnreadCount } from "redux/actions/activity";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Badge, Box, Container, Grid } from "@mui/material";
import AccountAvatarMenu from "components/Navbar/AccountAvatarMenu";
import { useAuthenticate } from "stores/authentication/helpers";
import useMediaScreen from "utils/Hooks/useMedia";
import { useSidebarStore } from "components/Layout/Sidebar";

// Images

function DashboardNavbar({ absolute, light, isMini }) {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useMediaScreen();
  const { userProfile } = useAuthenticate();

  const openSidebar = useSidebarStore((state) => state.openSidebar);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const dispatcher = useDispatch();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const getActivityUnreadCountResponse = useSelector(
    (state) => state.activity.getActivityUnreadCount
  );

  const [unreadActivity, setUnreadActivity] = useState(0);

  useEffect(() => {
    dispatcher(getActivityUnreadCount());
    const intervalId = setInterval(() => {
      dispatcher(getActivityUnreadCount());
    }, 3 * 60000); // 60 segundos

    return () => {
      // Limpia el intervalo cuando el componente se desmonta
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (getActivityUnreadCountResponse.data)
      setUnreadActivity(getActivityUnreadCountResponse.data.unread_count);
  }, [getActivityUnreadCountResponse]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenSidebar = () => {
    isMobile || isTablet
      ? openSidebar()
      : setMiniSidenav(dispatch, !miniSidenav);
  };

  // Render the notifications menu
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => ({
        ...navbar(theme, { transparentNavbar, absolute, light }),
        "& .MuiToolbar-root": { padding: 0 },
      })}
    >
      <Toolbar sx={{ gap: 2 }}>
        <Box flex={1} display={"flex"} alignItems={"center"}>
          <Box>
            <SuiBadge
              color="info"
              variant="gradient"
              container
              sx={{ cursor: "pointer" }}
              badgeContent={
                <Icon
                  color="black"
                  fontSize="medium"
                  onClick={handleOpenSidebar}
                >
                  {isMobile
                    ? "menu_close"
                    : miniSidenav
                    ? "menu_close"
                    : "menu_open"}
                </Icon>
              }
            />
          </Box>
          {!isMobile && (
            <Box>
              <SuiTypography
                style={{ marginLeft: "10px" }}
                variant="h5"
                fontWeight="medium"
              >
                {userProfile.first_name}
                <SuiTypography
                  style={{ marginLeft: "10px" }}
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  -{" "}
                  {userProfile.groups
                    ? capitalize(userProfile.groups[0].name)
                    : ""}{" "}
                  -
                </SuiTypography>

                <SuiTypography
                  style={{ marginLeft: "2px" }}
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {userProfile.management && userProfile.management.name}
                </SuiTypography>
              </SuiTypography>
            </Box>
          )}
        </Box>

        <SuiBox>
          <SuiBox color={light ? "white" : "inherit"}>
            <SuiBox>
              <Badge badgeContent={unreadActivity} color="error">
                <IconButton
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => navigate("/activity")}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    notifications
                  </Icon>
                </IconButton>
              </Badge>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <Box>
          <AccountAvatarMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
