import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import SuiBox from "components/SuiBox";
import { useDispatch, useSelector } from "react-redux";
import SuiButton from "components/SuiButton";
import { getCommunitiesByUser } from "redux/actions/communities";
import { Card, Grid } from "@mui/material";
import { addCommunityToSupervisor } from "redux/actions/users";
import SuiInput from "components/SuiInput";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
export default function CheckboxesGroup(props) {
  const dispatch = useDispatch();
  const communitiesResponse = useGetCommunities();
  const usersCommunities = useSelector((state) => state.users.getUsersCommunities);
  const [name, setName] = React.useState("");
  const [markedCommunities, setMarkedCommunities] = React.useState(
    usersCommunities
      ? usersCommunities.data
        ? usersCommunities.data.map((community) => community.slug)
        : []
      : []
  );
  const [communities, setcommunities] = React.useState([]);


  React.useEffect(() => {
    if (communitiesResponse.data) {
      setcommunities(communitiesResponse.data);
    }
  }, [communitiesResponse]);

  const handleChange = (event) => {
    if (markedCommunities.includes(event.target.name)) {
      setMarkedCommunities(
        markedCommunities.filter((community) => community !== event.target.name)
      );
      return;
    }
    setMarkedCommunities([...markedCommunities, event.target.name]);
  };

  React.useEffect(() => {
    setMarkedCommunities(
      usersCommunities
        ? usersCommunities.data
          ? usersCommunities.data.map((community) => community.slug)
          : []
        : []
    );
  }, [props.member]);

  const handleSubmmit = () => {
    let d = {
      user: props.member.email,
      communities: markedCommunities,
    };
    dispatch(addCommunityToSupervisor(d));
    props.handleClose();
  };

  return (
    <SuiBox sx={{ display: "flex", minWidth: "100%" }}>
      <Card sx={{ p: 3, minWidth: "100%" }}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Asignar Comunidades</FormLabel>
          <SuiInput
            style={{ marginTop: 2 }}
            size="small"
            label="Nombre"
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormGroup sx={{ p: 3 }}>
            <Grid container spacing={3}>
              {communities.map((community, i) => {
                if (community.name.toLowerCase().includes(name.toLowerCase()))
                  return (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={markedCommunities.includes(community.slug) ? true : false}
                            onChange={handleChange}
                            name={community.slug}
                          />
                        }
                        label={community.name}
                      />
                    </Grid>
                  );
              })}
            </Grid>
          </FormGroup>
        </FormControl>
        <SuiButton color="success" variant="gradient" size="small" onClick={handleSubmmit}>
          Guardar
        </SuiButton>
      </Card>
    </SuiBox>
  );
}
