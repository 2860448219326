/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
// Soft UI Dashboard PRO React components
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox';
import SuiInput from 'components/SuiInput';
import SuiButton from 'components/SuiButton';
// Soft UI Dashboard PRO React example components
import SuiDatePicker from 'components/SuiDatePicker';
import { addDocumentToTicket } from 'redux/actions/tickets';
import { useDispatch, useSelector } from 'react-redux';
import { getProvidersByManagement } from 'redux/actions/providers';
import { useParams } from 'react-router-dom';
import SuiSelect from 'components/SuiSelect';
import SuiTypography from 'components/SuiTypography';
import { getCommunitiesByUser } from 'redux/actions/communities';
import { getDocumentsTags } from 'redux/actions/documents';
import { addDocument } from 'redux/actions/documents';
import { createCertification } from 'redux/actions/maintenance';
import { updateDocument } from 'redux/actions/documents';
import CreateProvider from 'layouts/pages/providers/Create';
import { CircularProgress, Icon } from '@mui/material';
import FileInput from 'components/FileInput';
import heic2any from 'heic2any';
import MergePDFComponent from 'components/MergePDF';
import { useAuthenticationStore } from 'stores/authentication';

const optionsXd = [
  { label: 'Cotización', value: 'cotizacion' },
  { label: 'Certificación', value: 'certificacion' },
  { label: 'Cheque', value: 'cheque' },
  { label: 'Circular', value: 'circular' },
  { label: 'Comprobante de pago', value: 'comprobante-de-pago' },
  { label: 'Contrato', value: 'contrato' },
  { label: 'Contabilidad', value: 'contabilidad' },
  { label: 'Factura', value: 'factura' },
  { label: 'Imagen', value: 'imagen' },
  { label: 'Informe', value: 'informe' },
  { label: 'Legal', value: 'legal' },
  { label: 'Seguros', value: 'seguros' },
  { label: 'RRHH', value: 'rrhh' },
  { label: 'Orden de Trabajo', value: 'orden-de-trabajo' },
  { label: 'Otro', value: 'otro' },
];

const DocumentEditorForm = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadFile, setuploadFile] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(props.file.name);
  const [fileType, setfileType] = useState(props.slugType);

  const [tags, settags] = useState(
    props.file.tags == [{}]
      ? []
      : props.file.tags.map((tag) => {
          return { value: tag.name, label: tag.name };
        })
  );
  const [proveedor, setproveedor] = useState(
    props.file.service_provider ? props.file.service_provider.uuid : null
  );
  const [price, setprice] = useState(
    parseFloat(props.file.price).toLocaleString('es-CL', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  );
  const [community, setcommunity] = useState(props.community.slug);
  const [providers, setproviders] = useState([]);
  const [currencyType, setcurrencyType] = useState(props.file.currency);
  const [selectedDate, setSelectedDate] = useState(props.file.expires_on);
  const providersResponse = useSelector((state) => state.providers.getProvidersByManagement);
  const documentsTagsResponse = useSelector((state) => state.documents.getDocumentsTags);
  const [documentsTags, setdocumentsTags] = useState([]);
  const updateDocumentResponse = useSelector((state) => state.documents.updateDocument);
  const [description, setdescription] = useState(props.file.observations);
  const [taxes, settaxes] = useState(props.file.tax);
  const createProviderResponse = useSelector((state) => state.providers.createProviderByManagement);
  const [editDocument, setEditDocument] = useState(false);
  const [preview, setPreview] = useState(props.file.status);
  const [image, setimage] = useState();

  const compressImage = async (file) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (file.type === 'image/heic') {
      try {
        file = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.6,
        });
      } catch (error) {
        console.error('Error converting image:', error);
        return;
      }
    }

    img.onload = () => {
      const maxWidth = 800;
      const maxHeight = 800;
      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const compressed = canvas.toDataURL('image/jpeg', 0.7);

      // Convertir la imagen comprimida a un objeto File
      const byteString = atob(compressed.split(',')[1]);
      const type = compressed.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type });
      const compressedFile = new File([blob], file.name, { type: file.type });
      setimage(compressedFile);

      // Aquí puedes agregar tu código para enviar el archivo comprimido al servidor
    };

    if (file) {
      img.src = URL.createObjectURL(file);
    }
  };

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
    if (fileType == 'imagen') {
      compressImage(event.target.files[0]);
    }
    setIsFilePicked(true);
  };
  useEffect(() => {
    let slugManagement = useAuthenticationStore.getState().userProfile.management.slug;
    dispatch(getProvidersByManagement(slugManagement));
    dispatch(getDocumentsTags());
    if (slug) {
      setcommunity(slug);
    }
    if (props.communnity) {
      setcommunity(props.communnity);
    }
  }, []);

  useEffect(() => {
    if (createProviderResponse.data) {
      let slugManagement =
        useAuthenticationStore.getState().userProfileuseAuthenticationStoremanagement.slug;
      dispatch(getProvidersByManagement(slugManagement));
    }
  }, [createProviderResponse]);

  useEffect(() => {
    if (providersResponse.data !== undefined) {
      let options = providersResponse.data.map((provider) => {
        return { label: provider.executive_name || provider.razon_social, value: provider.uuid };
      });
      setproviders(options);
    }
  }, [providersResponse]);

  useEffect(() => {
    if (documentsTagsResponse.data !== undefined) {
      let options = documentsTagsResponse.data.map((tag) => {
        return { label: tag.name, value: tag.uuid };
      });
      setdocumentsTags(options);
    }
  }, [documentsTagsResponse]);

  useEffect(() => {
    if (updateDocumentResponse.data) {
      setLoading(false);
    }
  }, [updateDocumentResponse]);

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append('name', fileName);
    formData.append('observations', description);
    if (community) formData.append('community_slug', community);
    if (proveedor) formData.append('service_provider_uuid', proveedor);
    formData.append('price', price.replace(/[^\d,]/g, '').replace(',', '.'));
    formData.append('type_slug', fileType);
    if (isFilePicked) {
      if (fileType == 'imagen') {
        formData.append('file', image);
      } else formData.append('file', file);
    }

    formData.append('status', preview);
    formData.append('tax', taxes);
    formData.append('currency', currencyType);
    if (selectedDate) formData.append('expires_on', selectedDate);
    let tagsArray = JSON.stringify(
      tags.map((tag) => {
        return { name: tag.label };
      })
    );
    formData.append('tags', tagsArray);
    dispatch(updateDocument(props.file.uuid, formData));
  };

  return (
    <SuiBox height={'100%'}>
      <Card style={{ marginBottom: 20, overflow: 'visible', width: '100%', height: '100%' }}>
        <SuiBox position="relative">
          <SuiBox position="absolute" top={0} right={0} p={2}>
            <Icon onClick={() => props.toggleDrawer('right', false)}>close</Icon>
          </SuiBox>
        </SuiBox>
        <Grid container alignItems={'center'}>
          <SuiTypography component="h6" fontWeight="bold" p={3}>
            Editar documento
          </SuiTypography>
          {!props.fromReport && (
            <Grid item xs={12}>
              <SuiBox px={4} pb={1}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Tipo de documento
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  option={fileType}
                  size="small"
                  onChange={(e) => setfileType(e.value)}
                  placeholder="Tipo de archivo"
                  options={optionsXd}
                  value={fileType}
                />
              </SuiBox>
            </Grid>
          )}
          <SuiBox p={2} pt={0}>
            <Grid container width="100%" direction="row">
              <Grid item xs={12} p={2} pt={0}>
                <SuiBox mb={0} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Documento
                  </SuiTypography>{' '}
                  {editDocument ? (
                    <FileInput
                      onChange={changeHandler}
                      edit={true}
                      files={image ? [image] : file ? [file] : []}
                    />
                  ) : (
                    <>
                      <SuiTypography
                        component="a"
                        href={props.file.file}
                        target="_blank"
                        variant="caption"
                        fontWeight="bold"
                        color="info"
                      >
                        Ver
                      </SuiTypography>
                      <SuiTypography
                        component="p"
                        variant="caption"
                        fontWeight="bold"
                        color="info"
                        onClick={() => setEditDocument(true)}
                      >
                        Editar
                      </SuiTypography>
                    </>
                  )}
                </SuiBox>
              </Grid>
              {editDocument && (
                <Grid item xs={12} p={2} pt={0}>
                  <MergePDFComponent />
                </Grid>
              )}
              <Grid item xs={12} p={2} pt={0}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nombre del documento
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  size="small"
                  type="text"
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                  placeholder="Nombre del documento"
                />
              </Grid>
              {!slug && !props.community && !props.ticket && (
                <Grid item xs={12} p={2} pt={0}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Comunidad
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    size="small"
                    onChange={(e) => setcommunity(e.value)}
                    placeholder="Comunidad"
                    value={community}
                    options={communities}
                  />
                </Grid>
              )}
              {fileType != 'imagen' && (
                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={11} p={2} pt={0}>
                      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Proveedor
                        </SuiTypography>
                      </SuiBox>
                      <SuiSelect
                        size="small"
                        onChange={(e) => setproveedor(e.value)}
                        placeholder="Proveedor"
                        value={proveedor}
                        options={providers}
                      />
                    </Grid>
                    <Grid item xs={1} pt={2} pr={2}>
                      <CreateProvider icon={true} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {
                [
                  'certificacion',
                  'cotizacion',
                  'factura',
                  'cheque',
                  'comprobante-de-pago',
                  'contrato',
                  'seguros',
                  'otro',
                ].includes(fileType) && (
                  <Grid item xs={12} p={2} pt={0}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Fecha expiración
                    </SuiTypography>
                    <SuiDatePicker
                      style={{ zIndex: 1000000 }}
                      defaultValue={selectedDate}
                      onChange={(e) => setSelectedDate(e[0].toISOString().split('T')[0])}
                    />
                  </Grid>
                ) /*    switch (fileType) {
          case "cotizacion":
          case "factura":
          case "cheque":
          case "comprobante-de-pago":
          case "certificacion":
          case "contrato":*/
              }

              {(fileType == 'cotizacion' ||
                fileType == 'factura' ||
                fileType == 'cheque' ||
                fileType == 'comprobante-de-pago') && (
                <Grid item xs={12} p={2} pt={0}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Precio
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    size="small"
                    value={price}
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(/[^\d,]/g, '');
                      let lastIsComma = inputValue[inputValue.length - 1] == ',';
                      inputValue = inputValue.replace('.', '');
                      inputValue = inputValue.replace(',', '.');
                      // Convert the input value to a number and format as a currency
                      let formattedValue = parseFloat(inputValue).toLocaleString('es-CL', {
                        useGrouping: true,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      });
                      // If the last character is a comma, add it back
                      if (lastIsComma) {
                        formattedValue += ',';
                      }
                      // if formatted value is NaN set to 0
                      if (inputValue == '') {
                        formattedValue = '';
                      }
                      // Set the formatted currency value back to the input field
                      setprice(formattedValue);
                    }}
                    placeholder="Precio"
                  />
                </Grid>
              )}
              {(fileType == 'cotizacion' ||
                fileType == 'factura' ||
                fileType == 'cheque' ||
                fileType == 'comprobante-de-pago') && (
                <Grid item xs={12} p={2} pt={0}>
                  <SuiBox>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Impuestos
                    </SuiTypography>
                    <SuiSelect
                      size="small"
                      value={taxes}
                      onChange={(e) => {
                        settaxes(e.value);
                      }}
                      placeholder="..."
                      options={[
                        { label: '+ IVA', value: '+ IVA' },
                        { label: '+ Impuesto Boleta', value: '+ Impuesto Boleta' },
                        { label: 'N/A', value: ' ' },
                      ]}
                    />
                  </SuiBox>
                </Grid>
              )}
              {fileType == 'cotizacion' && (
                <Grid item xs={12} p={2} pt={0}>
                  <SuiBox>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Valoración en
                    </SuiTypography>
                    <SuiSelect
                      size="small"
                      value={currencyType}
                      onChange={(e) => {
                        setcurrencyType(e.value);
                      }}
                      placeholder="...."
                      options={[
                        { label: 'CLP', value: 'CLP' },
                        { label: 'UF', value: 'UF' },
                      ]}
                    />
                  </SuiBox>
                </Grid>
              )}
              {fileType == 'imagen' && (
                <Grid item xs={12}>
                  <SuiBox p={2} pt={0}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Antes o Después
                    </SuiTypography>
                    <SuiSelect
                      size="small"
                      value={preview}
                      onChange={(e) => {
                        setPreview(e.value);
                      }}
                      placeholder="Antes o Después"
                      options={[
                        { label: 'Antes', value: 'Antes' },
                        { label: 'Después', value: 'Después' },
                        { label: 'Otro', value: 'Otro' },
                      ]}
                    />
                  </SuiBox>
                </Grid>
              )}

              <Grid item xs={12} p={2} pt={0}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observación
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    size="small"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                    multiline
                    rows={5}
                  />
                </SuiBox>
              </Grid>

              <Grid item xs={12} p={2} pt={0}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Etiquetas documento
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    size="small"
                    options={documentsTags}
                    multiple
                    onInputChange={(e) => {
                      setdocumentsTags([...documentsTags, { label: e, value: e }]);
                    }}
                    onChange={(e) => settags(e)}
                    isMulti
                    value={tags}
                  />
                </SuiBox>
              </Grid>
              <div style={{ textAlign: 'center', width: '100%', padding: 10 }}>
                {' '}
                <SuiButton size="small" variant="gradient" onClick={handleSubmit} color="success">
                  {loading ? <CircularProgress size={12} /> : 'Guardar'}
                </SuiButton>
              </div>
            </Grid>
          </SuiBox>
        </Grid>
      </Card>
    </SuiBox>
  );
};

export default DocumentEditorForm;
