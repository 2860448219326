import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import View from "./View";
import SuiButton from "components/SuiButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  overflow: "scroll",
  bgColor: "background.paper",
  maxHeight: "90%",
  minWidth: "50%",
  p: 0,
};

export default function AssignCommunityModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ float: "right" }}>
      <SuiButton variant="gradient" color="info" size={"small"} onClick={handleOpen}>
        Asignar Comunidades
      </SuiButton>
      <Modal
        uuid={props.uuid}
        style={{ overflow: "scroll" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box sx={style}>
          <View handleClose={handleClose} member={props.member} />
        </Box>
      </Modal>
    </div>
  );
}
