import { TicketItemModel, TicketsPage } from "api/services/tickets/models";
import { SuiBadgeTs } from "components/SuiBadge";
// import { SuiProgressTs } from 'components/SuiProgress';
import MemberList from "components/Tickets/MemberList";
import TicketTableActions from "components/Tickets/TicketTableActions";
import { ColumnTable } from "components/SwappiTable/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { parseDate, ticketPriorityColor, ticketStatusColor } from "utils";
import { SelectedTicketsState } from "../types";
import { Box, Checkbox, Tooltip } from "@mui/material";
import TicketModal from "components/Tickets/TicketDetailModal";

interface ColumnsRequired {
  data: TicketsPage;
  selectedTickets: SelectedTicketsState;
  addTickets: (item: TicketItemModel[]) => void;
  removeTickets: (item: TicketItemModel[]) => void;
  refetch: () => void;
}
export const getColumns = ({
  selectedTickets,
  data,
  refetch,
  addTickets,
  removeTickets,
}: ColumnsRequired): ColumnTable<TicketItemModel>[] => {
  const handleCheckAll = (checkAll: boolean) => {
    if (checkAll) addTickets(data.results);
    else removeTickets(data.results);
  };

  const handleCheckbox = (checked: boolean, item: TicketItemModel) => {
    if (checked) addTickets([item]);
    else removeTickets([item]);
  };

  const validateCheckAll = () =>
    data?.results.every((ticket) => {
      return selectedTickets[ticket.uuid] !== undefined;
    });

  const allChecked = validateCheckAll();
  return [
    {
      header: () => (
        <Box onClick={() => handleCheckAll(!allChecked)} display={"flex"}>
          <Checkbox checked={allChecked} />
          <Tooltip
            title="Esta opción selecciona solo la hoja actual"
            placement="top"
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      ),
      accessorKey: "uuid",
      cell: ({ row }) => (
        <Checkbox
          checked={selectedTickets[row.original.uuid] !== undefined}
          sx={{ color: "white" }}
          onChange={(value) =>
            handleCheckbox(value.target.checked, row.original)
          }
        />
      ),
    },
    {
      header: "Título",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <TicketModal
          uuid={row.original.uuid}
          title={row.original.title}
          community={row.original.community}
        />
      ),
      width: 100,
    },
    {
      header: "Comunidad",
      accessorKey: "uuid",
      cell: ({ row }) => row.original.community.name,
      width: 100,
    },
    {
      header: "Responsables",
      accessorKey: "assignees",
      cell: ({ row }) => <MemberList members={row.original.assignees} />,
      width: 100,
    },
    {
      header: "Prioridad",
      accessorKey: "priority",
      cell: ({ row }) => (
        <SuiBadgeTs
          style={{ float: "left" }}
          badgeContent={row.original.priority}
          color={ticketPriorityColor[row.original.priority]}
          size="xs"
          container
        />
      ),
      width: 100,
    },
    {
      header: "Estado",
      accessorKey: "status",
      width: 100,
      cell: ({ row }) => (
        <SuiBadgeTs
          style={{ float: "left" }}
          badgeContent={row.original.status}
          color={ticketStatusColor[row.original.status]}
          size="xs"
          container
        />
      ),
    },
    // {
    //   header: 'Progreso',
    //   accessorKey: 'progress',
    //   style: {
    //     width: 100,
    //   },
    //   cell: (ticket) => (
    //     <SuiProgressTs value={(Number(ticket.progress) * 100).toFixed()} variant="gradient" label />
    //   ),
    // },
    {
      header: "Fecha inicio",
      accessorKey: "created",
      width: 100,

      cell: ({ row }) =>
        row.original.started_on
          ? parseDate(row.original.started_on)
          : row.original.created && parseDate(row.original.created),
    },
    {
      header: "Fecha término",
      accessorKey: "end_date",
      width: 100,
      cell: ({ row }) =>
        row.original.due_date && parseDate(row.original.due_date),
    },
    {
      header: "Acciones",
      accessorKey: "actions",

      cell: ({ row }) => (
        <TicketTableActions ticket={row.original} refetch={refetch} />
      ),
      width: 100,
    },
  ];
};
