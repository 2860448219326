import { Delete } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { queryClient } from "api/config/queryClient";
import { GET_MAINTENANCE_KEY } from "api/services/maintenances/queries/getMaintenance";
import { MaintenanceModel } from "api/services/maintenances/models";

import { VisitModel } from "api/services/visits/models";
import useDeleteVisit from "api/services/visits/mutations/deleteVisit";
import { SuiBadgeTs } from "components/SuiBadge";
import React from "react";
import Swal from "sweetalert2";

const DeleteVisitButton: React.FC<{
  visit: VisitModel;
  maintenance: MaintenanceModel;
}> = ({ visit, maintenance }) => {
  const { mutate: deleteVisit } = useDeleteVisit(visit.uuid);
  const handleDelete = () => {
    Swal.fire({
      title: "¿Quieres eliminar esta visita?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVisit(null, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [GET_MAINTENANCE_KEY, maintenance.uuid],
            });
          },
        });
      }
    });
  };
  return (
    <Tooltip title="Borrar visita" placement="top">
      <SuiBadgeTs
        onClick={handleDelete}
        badgeContent={<Delete />}
        sx={{ cursor: "pointer" }}
        color="error"
        variant="gradient"
        container
      />
    </Tooltip>
  );
};

export default DeleteVisitButton;
