import axios, { AxiosError } from 'axios';

type CbError = (error: AxiosError) => void;
export const handleAxiosError = (error: Error, callback: CbError) => {
  if (axios.isAxiosError(error)) {
    callback(error);
  } else {
    // console.error('An unexpected error occurred', error);
    // toast.error('An unexpected error occurred');
  }
};
