import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Form from './Form';
import { useSelector } from 'react-redux';
import './style.css';
import Icon from '@mui/material/Icon';
import { Tooltip } from '@mui/material';
import { SuiBadgeTs } from 'components/SuiBadge';

type EditFileDrawerProps = {
  file?: any;
  color?: any;
  slugType?: any;
  community?: any;
  deleteFile?: any;
  button?: any;
  style?: React.CSSProperties;
  fromReport?: any;
  onlyDrawer?: boolean;
};

export interface EditFileDrawerHandles {
  toggleDrawer: () => void;
}

const EditFileDrawer = forwardRef<EditFileDrawerHandles, EditFileDrawerProps>(
  (
    { file, color, slugType, community, deleteFile, button, style, fromReport, onlyDrawer = false },
    ref
  ) => {
    const updateDocumentResponse = useSelector((state: any) => state.documents.updateDocument);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => setIsOpen((state) => !state);

    useEffect(() => {
      if (updateDocumentResponse.data) {
        setIsOpen(false);
      }
    }, [updateDocumentResponse]);

    useImperativeHandle(ref, () => ({
      toggleDrawer,
    }));

    if (onlyDrawer)
      return (
        <Drawer anchor={'right'} open={isOpen} onClose={toggleDrawer} sx={{ zIndex: 99999 }}>
          <Box role="presentation">
            <Form
              file={file}
              slugType={slugType}
              community={community}
              fromReport={fromReport}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        </Drawer>
      );

    return (
      <div style={!button ? { float: 'right' } : { float: 'left' }}>
        {deleteFile}
        <Tooltip title={'Editar'} placement="top">
          {style ? (
            <span onClick={toggleDrawer} style={style}>
              <Icon>edit</Icon>
            </span>
          ) : button ? (
            <SuiBadgeTs
              variant="gradient"
              color={color}
              size={'small'}
              container
              badgeContent={
                <Icon style={{ float: 'right', cursor: 'pointer' }} onClick={toggleDrawer}>
                  edit
                </Icon>
              }
            />
          ) : (
            <Icon style={{ float: 'right', cursor: 'pointer' }} onClick={toggleDrawer}>
              edit
            </Icon>
          )}
        </Tooltip>

        <Drawer anchor={'right'} open={isOpen} onClose={toggleDrawer} sx={{ zIndex: 99999 }}>
          <Box role="presentation">
            <Form
              file={file}
              slugType={slugType}
              community={community}
              fromReport={fromReport}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        </Drawer>
      </div>
    );
  }
);

EditFileDrawer.displayName = 'EditFileDrawer';
export default EditFileDrawer;
