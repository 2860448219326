import { SuiInputTs } from "components/SuiInput";
import React, { useState } from "react";

interface Props {
  onChange: (value: string) => void;
  initialValue?: string;
}

const SearchInput: React.FC<Props> = ({ onChange, initialValue = "" }) => {
  const [input, setInput] = useState(initialValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInput(newValue);
    onChange(newValue);
  };

  return (
    <SuiInputTs
      placeholder="Buscar"
      value={input}
      onChange={handleInputChange}
    />
  );
};
export default SearchInput;
