/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import { getProvidersByManagement } from "redux/actions/providers";
import { getAreas } from "redux/actions/communities";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiDatePicker from "components/SuiDatePicker";
// Data
import SuiButton from "components/SuiButton";
import { createUnit } from "redux/actions/communities";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SuiInput from "components/SuiInput";
import { getPlanificationCategory } from "redux/actions/analytics";
import { createPlanification } from "redux/actions/analytics";
import { getCommunitiesByUser } from "redux/actions/communities";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";

function Form(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [name, setName] = useState("");
  const getCommunitiesByUserResponse = useGetCommunities();

  const [community, setCommunity] = useState(props.community ? props.community : null);
  const [communities, setCommunities] = useState([]);

  useEffect(() => {
    if (getCommunitiesByUserResponse.data) {
      setCommunity(getCommunitiesByUserResponse.data[0].slug);
      setCommunities(getCommunitiesByUserResponse.data);
    }
  }, [getCommunitiesByUserResponse]);

  useEffect(() => {
    if (!slug) {
    }
    dispatch(getPlanificationCategory());
  }, []);

  const handleSubmit = (e) => {
    let form = {
      year: name,
    };
    if (slug) {
      form.community = slug;
    } else {
      form.community = community;
    }
    dispatch(createPlanification(form));
    props.close();
  };

  return (
    <SuiBox>
      <Card id="basic-info" style={{ overflow: "visible" }}>
        <SuiBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuiBox>
                <SuiTypography variant="h5">Crear Planificación Anual</SuiTypography>
              </SuiBox>
              <SuiTypography component="label" variant="caption" fontWeight="bold">
                Comunidad
              </SuiTypography>
              <SuiSelect
                value={community}
                size="small"
                onChange={(e) => setCommunity(e.value)}
                options={
                  communities &&
                  communities.map((community) => {
                    return {
                      label: community.name,
                      value: community.slug,
                    };
                  })
                }
              />
              <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Año
                </SuiTypography>
              </SuiBox>

              <SuiInput
                placeholder="Año"
                size="small"
                type={"number"}
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          </Grid>
          <SuiBox mt={2}>
            <SuiButton variant={"gradient"} color="success" size="small" onClick={handleSubmit}>
              Crear
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default Form;
