import { useQuery } from "@tanstack/react-query";

import { UserImage } from "../models";
import apiClient from "api/config/axios";
import semaphore from "api/utils/Semaphore";

export const GET_PROFILE_KEY = "get_user_image";

const useGetUserImage = (uuid: string) => {
  const query = useQuery({
    staleTime: 1000 * 60 * 5,
    enabled: true,
    queryKey: [GET_PROFILE_KEY, uuid],
    queryFn: async () => {
      await semaphore.acquire(); // Adquirir un "permiso" del semáforo
      const url = `users/${uuid}/profile-picture/`;
      const profile = await apiClient
        .get<UserImage>(url)
        .then((res) => res.data);
      semaphore.release(); // Liberar el "permiso" del semáforo
      return profile;
    },
  });
  const data = query.data && query.data.url;

  return { ...query, data };
};

export default useGetUserImage;
