import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputControlled from 'components/forms/controlled/InputControlled';
import SwitchControlled from 'components/forms/controlled/SwitchControlled';
import { SuiButtonTs } from 'components/SuiButton';
import { Link } from 'react-router-dom';
import { Email } from 'types/models';
import useLogin from 'api/services/auth/mutation/login';
import { handleAxiosError } from 'api/utils/axiosErrors';
import { HttpStatus } from 'api/utils/http.enum';

interface FormValues {
  email: Email;
  password: string;
  stay_logged: boolean;
}
const LoginForm = () => {
  const [credentialError, setCredentialError] = useState<boolean>(false);
  const authenticateUser = useLogin();
  const [rememberMe, setRememberMe] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      stay_logged: false,
    },
  });


  useEffect(() => {

    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    const rememberMe = localStorage.getItem('rememberMe') === 'true';

    console.log(email, password, rememberMe);

    if (email) {
      setValue('email', email);
    }
    if (password) {
      setValue('password', password);
    }
    setValue('stay_logged', rememberMe);
    setRememberMe(rememberMe);
  }, [setValue]);

  const storeLogin = () => {
    const { email, password, stay_logged } = getValues(); 
    localStorage.setItem('email', email);
    localStorage.setItem('password', password);
    localStorage.setItem('rememberMe', stay_logged.toString());
  };


  const handleLogin = handleSubmit((data) => {
    if(getValues().stay_logged) {
      storeLogin();
    }
    authenticateUser.mutate(data, {
      onError: (e) =>
        handleAxiosError(e, (error) => {
          if (error.response.status === HttpStatus.HTTP_401_UNAUTHORIZED) {
            setCredentialError(true);
          }
        }),
    });
  });
  return (
      <form onSubmit={handleLogin}>
        <Stack spacing={2}>
          <Box>
            <Typography variant="label">
              <Box mb={1}>Email</Box>
              <InputControlled
                name="email"
                control={control}
                placeholder="Correo"
                size="medium"
                type="email"
                rules={{ required: true }}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </Typography>
          </Box>
          <Box>
            <Typography variant="label" mb={1}>
              <Box mb={1} display={'flex'} justifyContent={'space-between'}>
                Contraseña
                <Link to="/reset-password/" tabIndex={-1}>
                  ¿Olvidaste tu contraseña?
                </Link>
              </Box>
              <InputControlled
                name="password"
                control={control}
                placeholder="Contraseña"
                size="medium"
                type="password"
                rules={{ required: true }}
              />
              <Typography variant="label" color={'error.main'}>
                {credentialError ? 'Usuario o contraseña incorrectos. Intente nuevamente' : <br />}
              </Typography>
            </Typography>
          </Box>
        </Stack>
        <Typography
          component={'label'}
          fontSize={'size.sm'}
          display={'flex'}
          alignItems={'center'}
          gap={1}
        >
          <SwitchControlled name="stay_logged" control={control} />
          Recuérdame
        </Typography>
        <Box mt={1}>
          <SuiButtonTs
            variant="gradient"
            color="info"
            type="submit"
            fullWidth
            disabled={authenticateUser.isPending || !isValid}
          >
            {authenticateUser.isPending ? <CircularProgress size={15} color="inherit" /> : 'Entrar'}
          </SuiButtonTs>
        </Box>
      </form>
  );
};

export default LoginForm;
