import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { SuiSelectTs } from 'components/SuiSelect';
import { SelectOptionForm } from 'types/forms';

interface SelectProps extends UseControllerProps {
  label?: string;
  placeholder?: string;
  isMulti?: boolean;
  control: Control<any>;
  options?: SelectOptionForm[];
}

const SelectControlled: FC<SelectProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  options = [],
  isMulti,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label}
        </Typography>
      )}
      <SuiSelectTs
        {...field}
        onChange={(e) => field.onChange(isMulti ? e : e.value)}
        size={'small'}
        placeholder={placeholder}
        isMulti={isMulti}
        options={options}
      />
    </Box>
  );
};

export default SelectControlled;
