/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getDashboard } from "redux/actions/analytics";
import SuiButton from "components/SuiButton";
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import TaskListHeaderDefault from "./header";
import TaskListTableDefault from "./list";
import { Card, Checkbox } from "@mui/material";
import StandardTooltipContent from "./tooltip";
import SuiBadge from "components/SuiBadge";
import { getCommunityGantt } from "redux/actions/communities";
import { useNavigate, useParams } from "react-router-dom";
import LoadingView from "components/Loading";

function CommunityGantt({ rows }) {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const { slug } = useParams();
  const [viewMode, setViewMode] = useState(ViewMode.Month);
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const [showList, setShowList] = useState(true);
  const [showName, setShowName] = useState(true);
  const [showCommunity, setShowCommunity] = useState(true);
  const [showMaintenance, setShowMaintenance] = useState(true);
  const [showTasks, setShowTasks] = useState(true);
  const [showStartDate, setShowStartDate] = useState(true);
  const [showEndDate, setShowEndDate] = useState(true);
  const [showSeen, setShowSeen] = useState(true);
  const communityGanttResponse = useSelector((state) => state.communities.getCommunityGantt);

  useEffect(() => {
    dispatch(getCommunityGantt(slug));
  }, []);

  useEffect(() => {
    if (communityGanttResponse.data) {
      setDashboard(formattDataGantt(communityGanttResponse.data));

      setLoading(false);
    }
  }, [communityGanttResponse]);

  useEffect(() => {
    if (communityGanttResponse.data) {
      setDashboard(formattDataGantt(communityGanttResponse.data));
    }
  }, [showMaintenance, showTasks]);

  const handleFullScreen = () => {
    if (componentRef.current) {
      if (componentRef.current.requestFullscreen) {
        componentRef.current.requestFullscreen();
      } else if (componentRef.current.webkitRequestFullscreen) {
        componentRef.current.webkitRequestFullscreen();
      } else if (componentRef.current.msRequestFullscreen) {
        componentRef.current.msRequestFullscreen();
      }
    }
  };

  const formattDataGantt = (tickets) => {
    let m = tickets.maintenances.map((maintenance) => {
      let parent = null;
      return maintenance.visits.map((visit) => {
        let value = {
          id: visit.uuid,
          isVisit: true,
          visit: visit,
          maintenance: maintenance,
          start: new Date(visit.start),
          end: new Date(visit.end),
          name: maintenance.area.name,
          type: "task",
          progress: visit.completed ? 100 : 0,
          status: "",
          documents: "",
          community: "",
          priority: "",
          isDisabled: true,
          styles: {
            progressColor: "#98ec2d",
            progressSelectedColor: "red",
          },
          dependencies: [parent],
          project: maintenance.area.name,
        };
        parent = visit.uuid;
        return value;
      });
    });
    let t = tickets.tickets.filter((ticket) => ticket.due_date !== null);
    let rows = t.map((ticket, i) => {
      let date = Date.parse(ticket.created);
      let start = new Date(date);
      let end = new Date(Date.parse(ticket.due_date));
      return {
        start,
        end,
        name: ticket.title,
        id: ticket.uuid,
        type: "task",
        progress: ticket.progress * 100,
        status: ticket.status,
        documents: ticket.documents,
        community: ticket.community,
        priority: ticket.priority,
        isDisabled: true,
        styles: {
          progressColor: "#98ec2d",
          progressSelectedColor: "red",
        },
        project: ticket.community.slug,
      };
    });
    let return_data = [];
    if (showTasks) {
      return_data = [...return_data, ...rows];
    }
    if (showMaintenance) {
      m = [...m.flat()];
      return_data = [...return_data, ...m];
    }

    return return_data;
  };

  const renderContent = () => (
    <Card sx={{ padding: 5, marginBottom: 5, overflow: "scroll" }} ref={componentRef}>
      <SuiBox style={{ paddingBottom: 10, justifyContent: "flex-end", display: "flex" }}>
      <SuiButton onClick={() =>  navigator.clipboard.writeText('https://app.swappi.cl/public/community/'+ slug +'/gantt')} variant="gradient" color="success" size="small">
          Compartir Enlace
        </SuiButton>
        <SuiButton onClick={handleFullScreen} variant="gradient" color="info" size="small">
          Pantalla completa
        </SuiButton>
      </SuiBox>
      <SuiBox style={{ paddingBottom: 10 }}>
        <SuiTypography variant="h6" fontWeight="medium">
          Columnas
          <SuiBadge
            style={{ float: "right" }}
            color="info"
            variant="gradient"
            size="xs"
            badgeContent={"BETA"}
          />
        </SuiTypography>
        <Grid container>
          {/*<Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showName} onClick={() => setShowName(!showName)} />
            <SuiTypography variant="button" fontWeight="regular">
              Nombre
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showCommunity} onClick={() => setShowCommunity(!showCommunity)} />
            <SuiTypography variant="button" fontWeight="regular">
              Comunidad
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showStartDate} onClick={() => setShowStartDate(!showStartDate)} />
            <SuiTypography variant="button" fontWeight="regular">
              Desde
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showEndDate} onClick={() => setShowEndDate(!showEndDate)} />
            <SuiTypography variant="button" fontWeight="regular">
              Hasta
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showList} onClick={() => setShowSeen(!showSeen)} />
            <SuiTypography variant="button" fontWeight="regular">
              Ver
            </SuiTypography>
        </Grid>*/}
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox
              checked={showMaintenance}
              onClick={() => setShowMaintenance(!showMaintenance)}
            />
            <SuiTypography variant="button" fontWeight="regular">
              Mantenciones
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox checked={showTasks} onClick={() => setShowTasks(!showTasks)} />
            <SuiTypography variant="button" fontWeight="regular">
              Tareas
            </SuiTypography>
          </Grid>
          <Grid item xs={6} sm={4} lg={3}>
            <Checkbox value={showSeen} onClick={() => setShowList(!showList)} />
            <SuiTypography variant="button" fontWeight="regular">
              Ocultar todas
            </SuiTypography>
          </Grid>
        </Grid>
        <br />
        <SuiBadge
          size="xs"
          color={viewMode === ViewMode.Day ? "success" : "secondary"}
          variant="gradient"
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => setViewMode(ViewMode.Day)}
          badgeContent={"Vista de día"}
        />

        <SuiBadge
          size="xs"
          color={viewMode === ViewMode.Week ? "success" : "secondary"}
          variant="gradient"
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => setViewMode(ViewMode.Week)}
          badgeContent={"Vista de semana"}
        />
        <SuiBadge
          size="xs"
          color={viewMode === ViewMode.Month ? "success" : "secondary"}
          variant="gradient"
          style={{ marginLeft: 5, cursor: "pointer" }}
          onClick={() => setViewMode(ViewMode.Month)}
          badgeContent={"Vista de mes"}
        />
      </SuiBox>
      {dashboard.length > 0 && (
        <Gantt
          tasks={dashboard}
          locale={"es-CL"}
          viewMode={viewMode}
          TaskListHeader={TaskListHeaderDefault}
          TooltipContent={StandardTooltipContent}
          fontSize={14}
          columnFilter={{ showCommunity, showEndDate, showName, showSeen, showStartDate }}
          TaskListTable={TaskListTableDefault}
          listCellWidth={showList ? "155px" : ""}
          columnWidth={viewMode === ViewMode.Day ? 70 : viewMode === ViewMode.Week ? 100 : 150}
          headerHeight={50}
          rowWidth={70}
        />
      )}
    </Card>
  );

  return loading ? <LoadingView /> : renderContent();
}

export default CommunityGantt;
