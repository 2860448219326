import { Box, Typography } from "@mui/material";
import { SuiInputTs } from "components/SuiInput";
import React from "react";
import { Control, useController, UseControllerProps } from "react-hook-form";

interface InputProps extends UseControllerProps {
  control: Control<any>;
  label?: string;
  placeholder?: string;
  size?: "small" | "medium" | "large";
  type?: "password" | "email" | "number" | "file" | "year";
  multiline?: number;
}

const InputControlled: React.FC<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  size = "small",
  type,
  multiline,
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  const { value: fieldValue, ...restField } = field;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "file" && e.target.files) {
      field.onChange(e.target.files[0]);
    } else {
      field.onChange(e);
    }
  };

  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label}
        </Typography>
      )}
      <SuiInputTs
        {...restField}
        size={size}
        placeholder={placeholder}
        onChange={handleChange}
        type={type}
        {...rules}
        {...(type === "file" ? {} : { value: fieldValue })}
        {...(multiline && { multiline: true, rows: multiline })}
      />
    </Box>
  );
};

export default InputControlled;
