import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import SuiSelect from "components/SuiSelect";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Divider } from "@mui/material";
import SuiInput from "components/SuiInput";

import UploadFileDrawer from "components/UploadFileDrawer";

import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import DocumentsTable from "./DocumentsTable";
import useGetDocumentsTags from "api/services/documents/queries/getDocumentsTags";
import { usePermissions } from "stores/authentication/helpers";

const documentsTypes = [
  { name: "Imagen", slug: "imagen" },
  { name: "Visita", slug: "visita" },
  { name: "Contrato", slug: "contrato" },
  { name: "Comprobante de pago", slug: "comprobante-de-pago" },
  { name: "Cheque", slug: "cheque" },
  { name: "Factura", slug: "factura" },
  { name: "RRHH", slug: "rrhh" },
  { name: "Contabilidad", slug: "contabilidad" },
  { name: "Cotización", slug: "cotizacion" },
  { name: "Certificación", slug: "certificacion" },
  { name: "Informe", slug: "informe" },
  { name: "Legal", slug: "legal" },
  { name: "Seguros", slug: "seguros" },
  { name: "Circular", slug: "circular" },
  { name: "Orden de Trabajo", slug: "orden-de-trabajo" },
  { name: "Otro", slug: "otro" },
].sort((a, b) => a.name.localeCompare(b.name));

const Documents = ({
  areaUuid = undefined,
  providerUuid = undefined,
  visit = undefined,
  maintenanceProp: maintenance = undefined,
  user = undefined,
}) => {
  const { slug } = useParams();
  const canCreateDocument = usePermissions().documents.create;

  const { dataAsOptions: tagsOptions } = useGetDocumentsTags();
  const [selectedType, setselectedType] = useState("");
  const [selectedTag, setselectedTag] = useState([]);
  const [search, setsearch] = useState("");

  const communitiesResponse = useGetCommunities();
  const [communities, setCommunities] = useState(
    communitiesResponse?.data?.map((e) => {
      return { label: e.name, value: e.slug };
    })
  );
  const [community, setCommunity] = useState(null);

  return (
    <SuiBox mt={3}>
      <SuiTypography variant="h4" fontWeight="medium" p={3} pl={1}>
        Documentos
      </SuiTypography>

      {canCreateDocument && (
        <UploadFileDrawer
          user={user}
          areaUuid={areaUuid}
          title={"Subir documento"}
          color={"success"}
          maintenance={maintenance}
        />
      )}

      <Grid container>
        {!visit && (
          <Grid item xs={12} sm={3}>
            <Card
              style={{
                marginBottom: 20,
                float: "left",
                marginRight: 10,
                width: "90%",
                overflow: "visible",
              }}
            >
              <SuiTypography variant="h4" fontWeight="medium" p={3} pb={0}>
                Filtros
              </SuiTypography>
              <Divider />

              <SuiBox p={2} pb={0} pt={0}>
                <SuiTypography variant="h5" p={1} pt={0}>
                  Nombre
                </SuiTypography>
                <SuiInput
                  placeholder="Buscar..."
                  onChange={(e) => setsearch(e.target.value)}
                />
              </SuiBox>
              <Divider />
              {!slug && !maintenance && (
                <SuiBox>
                  <SuiBox p={2} pb={0} pt={0}>
                    <SuiTypography variant="h5" p={1} pt={0}>
                      Comunidad
                    </SuiTypography>
                    <SuiSelect
                      placeholder="Comunidad"
                      onChange={(e) => {
                        setCommunity(e.value);
                      }}
                      value={community}
                      options={[
                        { label: "Todas", value: null },
                        ...communities.map((community) => {
                          return {
                            value: community.value,
                            label: community.label,
                          };
                        }),
                      ]}
                    />
                  </SuiBox>
                  <Divider />
                </SuiBox>
              )}

              <SuiBox p={2} pb={0} pt={0}>
                <SuiTypography variant="h5" p={1} pt={0}>
                  Tipo
                </SuiTypography>
                {documentsTypes.map((type, i) => {
                  return (
                    <SuiBox key={i} style={{ paddingLeft: 5 }}>
                      <Checkbox
                        checked={selectedType === type.slug}
                        onClick={(e) =>
                          selectedType === type.slug
                            ? setselectedType("")
                            : setselectedType(type.slug)
                        }
                      />
                      <SuiTypography variant="span" fontWeight="regular">
                        {type.name}
                      </SuiTypography>
                    </SuiBox>
                  );
                })}
              </SuiBox>
              <Divider />
              <SuiBox p={2} pt={0}>
                <SuiTypography variant="h5" p={1} pt={0}>
                  Etiquetas
                </SuiTypography>
                <SuiSelect
                  isMulti={true}
                  size="small"
                  value={selectedTag}
                  placeholder="Tags"
                  options={tagsOptions}
                  onChange={(e) => setselectedTag(e)}
                />
              </SuiBox>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} sm={!visit ? 9 : 12}>
          <DocumentsTable
            filters={{
              ...(search.length > 0 && { name: search }),
              ...(selectedTag.length > 0 && {
                tags: selectedTag.map((e) => e.value).join(","),
              }),
              ...(selectedType && { type: selectedType }),
              ...(slug && { communities: `${slug},` }),
              ...(community && { communities: `${community},` }),
              ...(providerUuid && { service_provider: providerUuid }),
              ...(areaUuid && { area: areaUuid }),
              ...(visit && { visit: visit.uuid }),
              ...(maintenance && { maintenance: maintenance.uuid }),
              ...(user && { user: user.email }),
            }}
          />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default Documents;
