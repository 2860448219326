import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Form from './Form';
import SuiButton from 'components/SuiButton';
import { getPermission } from 'utils';
import { mobileMaxWidth } from 'utils';
import { isPermissionEnabled } from 'utils';
import SuiBadge from 'components/SuiBadge';
import { AiOutlineEdit } from 'react-icons/ai';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  width: '40vw',
  borderRadius: '10px',
  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  width: '100%',
  height: '80vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
};

export default function Permissions(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const editPermission = useSelector((state) => state.auth.editPermissions);

  useEffect(() => {
    setLoading(false);
  }, [editPermission]);

  return (
    getPermission(['superadmin', 'administrador']) &&
    isPermissionEnabled('permissions-editPermission') && (
      <div style={{ marginTop: 5 }}>
        {props.comitee ? (
          <SuiBadge
            color="info"
            variant="gradient"
            onClick={handleOpen}
            container
            size="small"
            badgeContent={<AiOutlineEdit size="12px" />}
          />
        ) : (
          <SuiButton variant="gradient" color="warning" size="small" onClick={handleOpen}>
            Editar Permisos de Usuario
          </SuiButton>
        )}
        <Modal
          style={{ overflow: 'scroll' }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          width={''}
        >
          <Box
            ref={scrollContainerRef}
            onScroll={(e) => {
              if (!loading) setScrollPosition(e.target.scrollTop);
            }}
            sx={window.innerWidth <= mobileMaxWidth ? styleMobile : style}
          >
            <Form
              handleClose={handleClose}
              user={props.user}
              scrollPosition={scrollPosition}
              scrollContainerRef={scrollContainerRef}
              setScrollPosition={setScrollPosition}
              setLoading={setLoading}
              loading={loading}
            />
          </Box>
        </Modal>
      </div>
    )
  );
}
