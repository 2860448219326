import { Box, Modal } from "@mui/material";
import { SuiButtonTs } from "components/SuiButton";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

interface ModalProps {
  children: any | React.ReactNode | React.JSX.Element;
  onNext?: () => void;
  onPrev?: () => void;
}

export interface ModalRefHandle {
  open: () => void;
  close: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "100vh",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "20px",
  p: 1,
};
const buttonProps = {
  variant: "gradient",
  color: "info",
  size: "small",
};

const navButtonStyle = {
  p: 0,
  position: "absolute",
  top: "50%",
  zIndex: 1,
};

const BaseModal = forwardRef<ModalRefHandle, ModalProps>(
  ({ onNext, onPrev, children }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }));

    const handleClose = () => setIsOpen(false);

    return (
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isOpen}
        onClose={handleClose}
      >
        <Box sx={style}>
          {onPrev && (
            <SuiButtonTs
              {...buttonProps}
              onClick={onPrev}
              sx={{ ...navButtonStyle, left: -40 }}
            >
              <AiOutlineLeft size="20px" />
            </SuiButtonTs>
          )}
          {children}
          {onNext && (
            <SuiButtonTs
              {...buttonProps}
              onClick={onNext}
              sx={{ ...navButtonStyle, right: -40, color: "black" }}
            >
              <AiOutlineRight size="20px" />
            </SuiButtonTs>
          )}
        </Box>
      </Modal>
    );
  }
);

BaseModal.displayName = "BaseModal";
export default BaseModal;
