import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "components/TabPanel";
import Card from "@mui/material/Card";

import { SuiTypographyTs } from "components/SuiTypography";

import { Box, Divider, Stack } from "@mui/material";

import useMediaScreen from "utils/Hooks/useMedia";
import GeneralTab from "components/pages/maintenances/view/tabs/GeneralTab";
import ContractsTab from "components/pages/maintenances/view/tabs/ContractsTab";
import VisitsTab from "components/pages/maintenances/view/tabs/VisitsTab";

import RepairsTab from "components/pages/maintenances/view/tabs/RepairsTab";
import EmergenciesTab from "components/pages/maintenances/view/tabs/EmergenciesTab";

import BudgetsTab from "components/pages/maintenances/view/tabs/BudgetsTab";

import { useAuthenticate, usePermissions } from "stores/authentication/helpers";
import CertificationsTab from "components/pages/maintenances/view/tabs/CertificationsTab";
import DocumentsTab from "components/pages/maintenances/view/tabs/DocumentsTab";
import ExportMaintenanceButton from "components/pages/maintenances/ExportMaintenanceButton";
import DeleteMaintenanceButton from "components/pages/maintenances/DeleteMaintenanceButton";
import { MaintenanceModel } from "api/services/maintenances/models";

export const EVENT_MAINTENANCE_DELETED = "maintenanceDeleted";

const MaintenanceView: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const { isMobile } = useMediaScreen();
  const { isAuthenticated } = useAuthenticate();
  const canDeleteMaintenance = usePermissions().maintenances.delete;

  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = [
    {
      label: "Información General",
      tab: <GeneralTab maintenance={maintenance} />,
    },
    {
      label: "Contratos",
      tab: <ContractsTab maintenance={maintenance} />,
    },
    {
      label: "Visitas",
      tab: <VisitsTab maintenance={maintenance} />,
    },
    ...(isAuthenticated
      ? [
          {
            label: "Reparaciones",
            tab: <RepairsTab maintenance={maintenance} />,
          },
        ]
      : []),
    {
      label: "Emergencias",
      tab: <EmergenciesTab maintenance={maintenance} />,
    },
    {
      label: "Certificaciones",
      tab: <CertificationsTab maintenance={maintenance} />,
    },
    {
      label: "Presupuestos",
      tab: <BudgetsTab maintenance={maintenance} />,
    },
    {
      label: "Documentos",
      tab: <DocumentsTab maintenance={maintenance} />, // TODO
    },
  ];

  return (
    <Card id="basic-info" sx={{ p: 3 }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <SuiTypographyTs variant="h5">
          Mantención {maintenance.area.name}{" "}
        </SuiTypographyTs>
        <Stack spacing={1}>
          {isAuthenticated && (
            <ExportMaintenanceButton maintenance={maintenance} />
          )}
          {canDeleteMaintenance && (
            <DeleteMaintenanceButton maintenance={maintenance} />
          )}
        </Stack>
      </Box>

      <Divider />
      <Box p={1}>
        <Box>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            orientation={isMobile ? "vertical" : "horizontal"}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {tabs.map((item) => (
              <Tab label={item.label} key={item.label} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((item, idx) => (
          <TabPanel value={currentTab} index={idx} key={idx}>
            {item.tab}
          </TabPanel>
        ))}
      </Box>
    </Card>
  );
};

export default MaintenanceView;
