import { useState, useEffect, FC } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { SuiTypographyTs } from 'components/SuiTypography';
import { SuiInputTs } from 'components/SuiInput';
import { SuiButtonTs } from 'components/SuiButton';
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';
import { Box, CircularProgress, ListItem, ListItemButton } from '@mui/material';
import DropdownMenu, { DropdownMenuItem } from 'components/DropdownMenu';
import { Delete } from '@mui/icons-material';
import useUpdateChecklistItem from 'api/services/checklist/mutations/UpdateChecklistItem';

import { ChecklistItemModel, ChecklistModel } from 'api/services/checklist/models';
import { ColorsTheme } from 'types/styles';
import useDeleteChecklistItem from 'api/services/checklist/mutations/DeleteChecklistItem';
import { UUID } from 'crypto';
const { borderWidth } = borders;

interface Props {
  item: ChecklistItemModel;
  checklist: ChecklistModel;
  objectUUID: UUID;
  onEdit?: (checklist: ChecklistModel) => void;
  editEnabled: boolean;
  color?: ColorsTheme;
}

const ChecklistItem: FC<Props> = ({
  item: checklistItem,
  checklist,
  objectUUID,
  color = ColorsTheme.Info,
  onEdit,
  editEnabled = false,
}) => {
  const borderStyle = `${borderWidth[3]} solid ${colors[color].main}`;

  const [isEditing, setIsEditing] = useState(false);
  const { mutate: updateChecklistItem, isPending: isSubmiting } = useUpdateChecklistItem({
    itemUUID: checklistItem.uuid,
    checklistUUID: checklist.uuid,
    objectUUID,
  });
  const { mutate: deleteChecklistItem } = useDeleteChecklistItem({
    itemUUID: checklistItem.uuid,
    checklistUUID: checklist.uuid,
    objectUUID,
  });

  const [itemName, setItemName] = useState('');
  const [itemDone, setItemDone] = useState(false);

  useEffect(() => {
    setItemName(checklistItem.name);
    setItemDone(checklistItem.done);
  }, [checklistItem]);

  const onSuccessEdit = (updatedItem: ChecklistItemModel) => {
    onEdit?.({
      ...checklist,
      items: checklist.items.map((item) => (item.uuid === updatedItem.uuid ? updatedItem : item)),
    });
  };
  const handleSetChecked = (checked) => {
    setItemDone(checked);
    updateChecklistItem(
      { done: checked },
      {
        onSuccess: (updatedItem) => {
          onSuccessEdit(updatedItem);
        },
      }
    );
  };

  const handleSubmitName = () => {
    updateChecklistItem(
      { name: itemName },
      {
        onSuccess: (updatedItem) => {
          onSuccessEdit(updatedItem);
          setIsEditing(false);
        },
      }
    );
  };

  const onDisableEdit = () => {
    setIsEditing(false);
  };
  const onEnableEdit = () => {
    setIsEditing(true);
    setItemName(checklistItem.name);
  };
  const menuItems: DropdownMenuItem[] = [
    {
      label: 'Eliminar',
      icon: <Delete />,
      onClick: () =>
        deleteChecklistItem(null, {
          onSuccess: () => {
            onEdit?.({
              ...checklist,
              items: checklist.items.filter((item) => item.uuid !== checklistItem.uuid),
            });
          },
        }),
    },
  ];
  return (
    <ListItem sx={{ my: 1 }}>
      {isEditing ? (
        <Box display={'flex'} sx={{ flex: 1, gap: 1 }}>
          <Box>
            <SuiTypographyTs variant="button" fontWeight="medium">
              Nombre:
            </SuiTypographyTs>
          </Box>
          <Box sx={{ flex: 1 }}>
            <SuiInputTs
              size={'small'}
              placeholder={'Activdad'}
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </Box>
          <Box>
            <SuiButtonTs
              sx={{ mr: 1 }}
              variant="gradient"
              color="secondary"
              size="small"
              onClick={onDisableEdit}
            >
              Cancelar
            </SuiButtonTs>
            <SuiButtonTs
              variant="gradient"
              color="success"
              size="small"
              disabled={itemName === checklistItem.name || isSubmiting}
              onClick={handleSubmitName}
            >
              <Box width={80}>
                {isSubmiting ? <CircularProgress size={16} color="inherit" /> : 'Guardar'}
              </Box>
            </SuiButtonTs>
          </Box>
        </Box>
      ) : (
        <ListItemButton sx={{ gap: 2, borderLeft: borderStyle, py: 0 }}>
          <Box>
            <Checkbox
              checked={itemDone}
              onChange={(e) => handleSetChecked(e.target.checked)}
              disabled={!editEnabled || isSubmiting}
            />
          </Box>
          <Box sx={{ flex: 1 }} onClick={() => editEnabled && onEnableEdit()}>
            <SuiTypographyTs variant="button" fontWeight="medium">
              {checklistItem.name || <br />}
            </SuiTypographyTs>
          </Box>
          {editEnabled && <DropdownMenu items={menuItems} />}
        </ListItemButton>
      )}
    </ListItem>
  );
};

export default ChecklistItem;
