/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import Footer from 'examples/Footer';
import Permissions from './permissions/View';

// Teams page components
import Header from 'layouts/pages/profile/components/Header';
import Members from './components/Post/Members';
import Post from './components/Post';
import { getUsersByManagement } from 'redux/actions/users';

import Profile from './components/Post/Members/Profile';
import Loading from 'components/Loading';
import { useLocation, useParams } from 'react-router-dom';
import { getCommunityStaff } from 'redux/actions/communities';
import LoadingView from 'components/Loading';
import { useAuthenticationStore } from 'stores/authentication';

function Teams(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  // TeamProfileCard dropdown menu state
  const [marketingMenu, setMarketingMenu] = useState(null);
  const [designMenu, setDesignMenu] = useState(null);
  const [member, setmember] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  // TeamProfileCard dropdown menu handlers
  const openMarketingMenu = (event) => setMarketingMenu(event.currentTarget);
  const closeMarketingMenu = () => setMarketingMenu(null);
  const openDesignMenu = (event) => setDesignMenu(event.currentTarget);
  const closeDesignMenu = () => setDesignMenu(null);
  const [usersManagement, setusersManagement] = useState([]);
  const usersManagementResponse = useSelector((state) => state.users.getUsersByManagement);
  const createUserResponse = useSelector((state) => state.users.createUser);
  const addCommunityToSupervisor = useSelector((state) => state.users.addCommunityToSupervisor);
  const deactiveUserResponse = useSelector((state) => state.users.deactiveUser);
  const updateUserResponse = useSelector((state) => state.users.updateUserWithAdmin);
  const communityStaffResponse = useSelector((state) => state.users.getCommunityStaff);
  const editPermissionsResponse = useSelector((state) => state.auth.editPermissions);
  const weeklyReportResponse = useSelector((state) => state.users.weeklyReportConfig);

  const [staffRole, setStaffRole] = useState('conserje');

  useEffect(() => {
    setLoading(true);
    if (slug) {
      dispatch(getCommunityStaff(slug));
    } else {
      let profile = useAuthenticationStore.getState().userProfile;
      dispatch(getUsersByManagement(profile.management.slug));
    }
  }, []);

  useEffect(() => {
    if (weeklyReportResponse)
      if (weeklyReportResponse.data)
        if (new Date() - weeklyReportResponse.time < 2000) {
          let profile = useAuthenticationStore.getState().userProfile;
          dispatch(getUsersByManagement(profile.management.slug));
        }
  }, [weeklyReportResponse]);

  useEffect(() => {
    if (slug) return;
    if (!usersManagementResponse) return;
    if (usersManagementResponse.data) {
      let staff = usersManagementResponse.data;
      staff = staff.sort((a, b) => {
        if (a.groups[0].name !== b.groups[0].name) {
          return a.groups[0].name.localeCompare(b.groups[0].name);
        } else {
          return a.first_name.localeCompare(b.first_name);
        }
      });
      setusersManagement(staff);
      setLoading(false);
      if (usersManagementResponse.data.length > 0) {
        if (
          member == {} ||
          usersManagementResponse.data.findIndex((x) => x.email === member.email) < 0
        ) {
          setmember(usersManagementResponse.data[0]);
        } else {
          setmember(
            usersManagementResponse.data[
              usersManagementResponse.data.findIndex((x) => x.email === member.email)
            ]
          );
        }
      }
    }
  }, [usersManagementResponse]);

  useEffect(() => {
    if (editPermissionsResponse) {
      if (editPermissionsResponse.data) {
        if (slug) {
          dispatch(getCommunityStaff(slug));
        } else {
          let profile = useAuthenticationStore.getState().userProfile;
          dispatch(getUsersByManagement(profile.management.slug));
        }
      }
    }
  }, [editPermissionsResponse]);

  useEffect(() => {
    setLoading(true);
  }, [location.pathname]);

  useEffect(() => {
    if (!communityStaffResponse) return;
    if (communityStaffResponse.data) {
      setusersManagement(communityStaffResponse.data);
      setLoading(false);
      if (communityStaffResponse.data.length > 0) {
        if (
          member == {} ||
          communityStaffResponse.data.findIndex((x) => x.email === member.email) < 0
        ) {
          setmember(communityStaffResponse.data[0]);
        } else {
          setmember(
            communityStaffResponse.data[
              communityStaffResponse.data.findIndex((x) => x.email === member.email)
            ]
          );
        }
      }
    }
  }, [communityStaffResponse]);

  useEffect(() => {
    if (createUserResponse.data || updateUserResponse.data) {
      if (slug) {
        dispatch(getCommunityStaff(slug));
      } else {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getUsersByManagement(profile.management.slug));
      }
    }
  }, [createUserResponse, updateUserResponse]);

  useEffect(() => {
    if (addCommunityToSupervisor) {
      if (addCommunityToSupervisor.data) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getUsersByManagement(profile.management.slug));
      }
    }
  }, [addCommunityToSupervisor]);

  useEffect(() => {
    if (deactiveUserResponse) {
      if (deactiveUserResponse.data) {
        if (slug) {
          dispatch(getCommunityStaff(slug));
        } else {
          let profile = useAuthenticationStore.getState().userProfile;
          dispatch(getUsersByManagement(profile.management.slug));
        }
      }
    }
  }, [deactiveUserResponse]);

  if (loading) return <LoadingView />;
  return (
    <SuiBox>
      <SuiBox my={3}>
        <Members members={usersManagement} setmember={setmember} community={props.community} />
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <SuiBox display="flex" style={{ justifyContent: 'flex-end', width: '100%' }}>
            <Permissions user={member} />
          </SuiBox>
          <Profile memberProp={member} community={props.community} />
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Teams;
