import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from 'redux/actions/types';
import { SelectOptionForm } from 'types/forms';
import { userToken } from 'utils/user/user';
import { UserMin } from '../../tickets/models';
import { getPermission } from 'utils';

const KEY_GET_USER_STAFF = 'GetUserStaff';
export const useGetUserStaff = (input = { enabled: true }) => {
  const query = useQuery<UserMin[]>({
    enabled: input.enabled,
    queryKey: [KEY_GET_USER_STAFF],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      if (!getPermission(['superadmin', 'administrador', 'supervisor'])) return [];
      const { slug } = userToken().management;
      const url = `${API_URL}/users/manager/${slug}/staff`;
      return axios
        .get<UserMin[]>(url)
        .then((res) => res.data)
        .catch((error) => {
          return [];
        });
    },
  });

  const data = query.data || [];
  const dataAsOptions: SelectOptionForm[] = data.map((member) => ({
    value: member.email,
    label: member.first_name,
  }));

  return { ...query, dataAsOptions, data };
};
