import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { SuiSelectTs } from 'components/SuiSelect';
import { SelectOptionForm } from 'types/forms';
import { components } from 'react-select';

interface SelectProps extends UseControllerProps {
  label?: string;
  placeholder?: string;
  control: Control<any>;
  options?: SelectOptionForm[];
  size?: 'small' | 'medium' | 'large';
}

const CustomOption = (props) => {
  //   const CComponent = props.data.icon;
  return (
    <div style={{ display: 'flex' }}>
      <components.Option {...props}>
        <input key={props.value} type="checkbox" checked={props.isSelected} onChange={() => {}} />
        <label style={{ marginLeft: '5px' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiSelectControlled: FC<SelectProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  options = [],
  size = 'small',
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <Box>
      {label && (
        <Typography variant="h6" fontWeight="medium">
          {label}
        </Typography>
      )}
      <SuiSelectTs
        {...field}
        components={{ Option: CustomOption }}
        onChange={(e) => field.onChange(e)}
        size={size}
        placeholder={placeholder}
        isMulti
        options={options}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    </Box>
  );
};

export default MultiSelectControlled;
