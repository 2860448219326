import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { VisitModel } from "../models";
import { toastNotification } from "components/ui/ToastNotification";
interface Params {
  uuid: UUID;
}
interface Input {
  file: File;
  name: string;
}
const useAddVisitDocument = (params: Params) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const { file, name } = input;
      const url = `${API_URL}/visit/${params.uuid}/add-document`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      return axios.post<VisitModel>(url, formData).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Documento subido",
        message: "El documento ha sido subido correctamente",
      });
    },
  });
};

export default useAddVisitDocument;
