/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SuiEditor from "components/SuiEditor";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "layouts/pages/account/settings/components/TableCell";
import Switch from "@mui/material/Switch";
import SuiSelect from "components/SuiSelect";
import SuiTagInput from "components/SuiTagInput";
import Checkbox from "@mui/material/Checkbox";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import SuiInput from "components/SuiInput";
// Data
import SuiButton from "components/SuiButton";
import { createTicket } from "redux/actions/tickets";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCommunitiesByUser } from "redux/actions/communities";
import { getPermission } from "utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Divider } from "@mui/material";
import { editPermissions } from "redux/actions/auth";
import LoadingView from "components/Loading";
function Form(props) {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const usersManagementResponse = useSelector((state) => state.users.getUsersByManagement);

  useEffect(() => {
    if (new Date() - usersManagementResponse.time < 1000 && usersManagementResponse.data) {
      props.setLoading(false);
    }
  }, [usersManagementResponse]);


  useEffect(() => {
    if (!props.loading) {
      // Restaurar la posición del scroll después de que termine la carga
      if (props.scrollContainerRef.current) {
        console.log(props.scrollPosition);
        props.scrollContainerRef.current.scrollTop = props.scrollPosition;
      }
    }
  }, [props.loading]);

  return (
    <Card id="basic-info" sx={{ overflow: "scroll" }}>
      <SuiBox p={3}>
        <SuiTypography variant="h4" fontWeight="medium">
          Permisos de usuario
        </SuiTypography>
        {props.loading ? (
          <LoadingView />
        ) : (
          Object.keys(props.user.permissions).map((permission) => {
            return (
              <SuiBox p={2}>
                <Table sx={{ minWidth: "36rem", paddingTop: 10 }}>
                  <SuiBox component="thead">
                    <TableRow>
                      <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                        {props.user.permissions[permission].label}
                      </TableCell>
                      <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                        Estado
                      </TableCell>
                    </TableRow>
                  </SuiBox>
                  <TableBody>
                    {Object.keys(props.user.permissions[permission]).map((item) => {
                      return (
                        props.user.permissions[permission][item].label && (
                          <TableRow>
                            <TableCell padding={[1, 1, 1, 0.5]}>
                              <SuiTypography display="block" variant="button" fontWeight="regular">
                                {props.user.permissions[permission][item].label}
                              </SuiTypography>
                            </TableCell>
                            <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                              <Switch
                                checked={props.user.permissions[permission][item].enabled}
                                onChange={(e) => {
                                  props.user.permissions[permission][item].enabled =
                                    e.target.checked;
                                  props.setLoading(true);
                                  dispatch(
                                    editPermissions({
                                      permissions: props.user.permissions,
                                      email: props.user.email,
                                    })
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </SuiBox>
            );
          })
        )}
      </SuiBox>
    </Card>
  );
}

export default Form;
