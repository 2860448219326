import { FC, useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { SuiInputTs } from 'components/SuiInput';
import { SuiSelectTs } from 'components/SuiSelect';
import { FiltersProps } from './types';
import { useGetUserStaff } from 'api/services/staff/queries/getUserStaff';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';
import useDebounce from 'utils/Hooks/useDebounce';
import { useExportCenterFiltersStore } from '../store';

const Filters: FC<FiltersProps> = ({ setFilters }) => {
  const { dataAsOptions: communitiesOptions } = useGetCommunities();
  const { dataAsOptions: membersOptions } = useGetUserStaff();

  const [title, setTitle] = useState('');
  const { community, setCommunity } = useExportCenterFiltersStore();
  const [selectedMember, setSelectedMember] = useState('');

  const debounceFilters = useDebounce(() => {
    setFilters({
      author: selectedMember,
      community: community,
      title,
    });
  }, 1000);
  useEffect(() => {
    debounceFilters();
  }, [title, community, selectedMember]);

  return (
    <Box>
      <Typography variant="h5" fontWeight="medium">
        Filtros
      </Typography>
      <Divider />
      <Grid container spacing={2} sx={{ overflow: 'visible' }}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Título
          </Typography>
          <SuiInputTs
            placeholder="Título"
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Comunidad
          </Typography>
          <SuiSelectTs
            size="small"
            placeholder="Comunidad"
            value={community}
            onChange={(e) => setCommunity(e.value)}
            options={[{ label: 'Todas', value: null }, ...communitiesOptions]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Colaborador
          </Typography>
          <SuiSelectTs
            size="small"
            value={selectedMember}
            options={[{ label: 'Todos', value: '' }, ...membersOptions]}
            onChange={(e) => setSelectedMember(e.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
