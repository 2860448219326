/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import React from 'react';

import Profile from 'layouts/pages/account/settings';
import Error404 from 'layouts/authentication/error/404';
// import Error500 from 'layouts/authentication/error/500';
import DocumentsByManagement from 'layouts/pages/documents/ByManagement';
import Teams from 'layouts/pages/admin-team';
import Calendar from 'layouts/pages/calendar';
import NewBuilding from 'layouts/pages/communities/community/buildings/Create';
import Providers from 'layouts/pages/providers';
import Maintenance from 'layouts/pages/maintenance';
import VisitReport from 'layouts/pages/calendar/report';
import { FaRegMoneyBillAlt } from 'react-icons/fa';

// Soft UI Dashboard PRO React icons
import Office from 'examples/Icons/Office';

import CustomerSupport from 'examples/Icons/CustomerSupport';
import Provider from 'layouts/pages/providers/View';
import Dashboard from 'layouts/pages/charts';
import { FaFloppyDisk } from 'react-icons/fa6';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaTruckLoading } from 'react-icons/fa';
import { AiOutlineInbox } from 'react-icons/ai';
import { SiReadthedocs } from 'react-icons/si';
import Areas from 'layouts/pages/communities/community/areas';
import { AccountCircle } from '@mui/icons-material';
import { GrHostMaintenance } from 'react-icons/gr';
import { MdEngineering, MdOutlineBackupTable } from 'react-icons/md';

import { TbLayoutDashboard, TbReportSearch } from 'react-icons/tb';
import AdmiVisit from 'layouts/pages/admi-visit';
import HelpCenter from 'layouts/pages/helpCenter';
import AnualPlanifiaction from 'layouts/pages/communities/community/anual-planification';
import { Activity } from 'layouts/pages/activity';
import Billing from 'layouts/pages/billing';
import ActionPlan from 'layouts/pages/actionPlan';
import { Route } from 'react-router-dom';
import { SwappiRouteProps, GroupRole } from 'routes/types';
import DataExportCenter from 'layouts/pages/export-center';
import CommunityManagement from 'layouts/pages/communities/community';
import TicketsPage from 'pages/tickets';
import CommunitiesPage from 'pages/communities';
import MaintenancesPage from 'pages/maintenances';
import MaintenanceDetailPage from 'pages/maintenances/[uuid]';

const communitiesRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Comunidades',
    path: '/communities',
    key: 'communities',
    icon: <Office size="12px" />,
    component: <CommunitiesPage />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
  {
    key: 'community-detail',
    path: '/communities/:slug',
    component: <CommunityManagement />,
    permission: [GroupRole.Superadmin, GroupRole.Administrador, GroupRole.Supervisor],
  },
  {
    key: 'new-building',
    path: '/communities/:slug/new-building',
    component: <NewBuilding />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];

const dashboardRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Panel',
    key: 'admin-dashboard',
    icon: <TbLayoutDashboard size="12px" />,
    component: <Dashboard key="dashboard" />,

    path: '/dashboard',
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];
const teamRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Equipo',
    key: 'admin-team',
    icon: <CustomerSupport size="12px" />,
    component: <Teams />,
    path: '/team',
    permission: [GroupRole.Administrador, GroupRole.Superadmin],
  },
];
const providersRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Proveedores',
    key: 'providers',
    path: '/providers',
    component: <Providers />,
    icon: <FaTruckLoading size="12px" />,

    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];

const exportDataRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Centro de Exportación',
    key: 'export-center',
    icon: <FaFloppyDisk size={12} />,
    component: <DataExportCenter />,
    path: '/export-data',
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];

const actionsPlanRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Plan de Acción',
    key: 'actionPlan',
    path: '/action-plan',
    component: <ActionPlan />,
    icon: <SiReadthedocs size="12px" />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];

const ticketsRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Tareas',
    key: 'ticketsPage',
    path: '/tickets',
    component: <TicketsPage />,
    icon: <AiOutlineInbox size="12px" />,

    permission: [],
  },
];

const fieldVisitsRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Visitas en Terreno',
    key: 'field-visits',
    path: '/visits',
    component: <AdmiVisit />,
    icon: <TbReportSearch size="12px" />,

    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];
const maintenancesRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Mantenciones',
    key: 'maintenances',
    path: '/maintenances',
    component: <MaintenancesPage />,
    icon: <MdEngineering size="12px" />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
  {
    name: 'Mantenciones',
    key: 'maintenances-detail',
    path: '/maintenances/:uuid',
    component: <MaintenanceDetailPage />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },

  {
    name: 'Mantenciones',
    key: 'maintenances-visits-detail',
    path: '/maintenances/visits/:uuid',
    component: <MaintenanceDetailPage />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];

const anualPlanificationRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Planificación Anual',
    key: 'anual-planification',
    path: '/anual-planification',
    component: <AnualPlanifiaction global={true} />,
    icon: <MdOutlineBackupTable size="12px" />,

    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];

const calendarRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Calendario',
    key: 'calendar',
    path: '/calendar',
    component: <Calendar />,
    icon: <FaCalendarAlt size="12px" />,

    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
];
const documentRoutes: SwappiRouteProps[] = [
  {
    type: 'collapse',
    name: 'Documentación',
    key: 'documentation',
    path: '/documents',
    component: <DocumentsByManagement />,
    icon: <SiReadthedocs size="12px" />,

    permission: [],
  },
];

const routes: SwappiRouteProps[] = [
  ...communitiesRoutes,
  ...dashboardRoutes,
  ...teamRoutes,
  ...providersRoutes,
  ...exportDataRoutes,
  {
    key: 'divider-1',
    type: 'divider',
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
  ...actionsPlanRoutes,
  ...ticketsRoutes,
  ...fieldVisitsRoutes,
  ...maintenancesRoutes,
  ...anualPlanificationRoutes,
  ...calendarRoutes,
  ...documentRoutes,
  {
    type: 'collapse',
    name: 'Mantenciones',
    key: 'maintenance-comite-calendar',
    icon: <GrHostMaintenance size="12px" />,
    component: <Maintenance />,

    path: '/maintenances-calendar/:slug',
    permission: [GroupRole.Comite, GroupRole.Conserje, GroupRole.Mayordomo],
  },
  {
    type: 'collapse',
    name: 'Areas Comunes',
    icon: <Office size="12px" />,
    component: <Areas comite={true} />,
    key: 'areas-comite',

    path: '/areacomun/:slug',
    permission: [GroupRole.Comite],
  },
  {
    type: 'collapse',
    name: 'Calendario',
    key: 'calendar',
    path: '/calendar/:slug',
    component: <Calendar comite={true} />,
    icon: <FaCalendarAlt size="12px" />,

    permission: [GroupRole.Comite],
  },

  /* Conserje */
  {
    type: 'collapse',
    name: 'Visitas en Terreno',
    key: 'admi-visits-conserjeria',
    component: <AdmiVisit conserjeria={true} />,
    icon: <TbReportSearch size="12px" />,

    path: '/visits/:slug',
    permission: [GroupRole.Conserje, GroupRole.Mayordomo, GroupRole.Comite],
  },
  {
    type: 'collapse',
    name: 'Areas Comunes',
    icon: <Office size="12px" />,
    component: <Areas conserjeria={true} />,
    key: 'areas-conserjeria',

    path: '/comunnity/:slug/areacomun',
    permission: [GroupRole.Conserje, GroupRole.Mayordomo],
  },

  {
    type: 'collapse',
    name: 'Planificación Anual',
    key: 'anual-planification',
    path: '/anual-planification/:slug',
    component: <AnualPlanifiaction comite={true} global={true} />,
    icon: <MdOutlineBackupTable size="12px" />,

    permission: [GroupRole.Conserje, GroupRole.Mayordomo, GroupRole.Comite],
  },

  {
    type: 'collapse',
    name: 'Calendario',
    key: 'calendar-conserjeria',
    path: '/community/:slug/calendar',
    component: <Calendar conserjeria={true} />,
    icon: <FaCalendarAlt size="12px" />,

    permission: [GroupRole.Conserje, GroupRole.Mayordomo],
  },
  {
    key: 'divider-3',
    type: 'divider',
  },
  {
    type: 'collapse',
    name: 'Perfil',
    icon: <AccountCircle />,
    component: <Profile />,
    key: 'user-profile',

    path: '/profile',
    permission: [],
  },
  {
    type: 'collapse',
    name: 'Facturación',
    icon: <FaRegMoneyBillAlt size="12px" />,
    component: <Billing />,
    key: 'billing',

    path: '/billing',
    permission: [GroupRole.Superadmin],
  },
  // HIDDEN ROUTES

  {
    key: 'provider-view',
    path: '/providers/:providerUuid',
    component: <Provider />,
    permission: [
      GroupRole.Administrador,
      GroupRole.Supervisor,
      GroupRole.Comite,
      GroupRole.Superadmin,
    ],
  },
  {
    key: 'visit-report',
    path: '/visits/report/:uuid',
    component: <VisitReport />,
    permission: [GroupRole.Administrador, GroupRole.Supervisor, GroupRole.Superadmin],
  },
  {
    key: 'activity',
    path: '/activity',
    component: <Activity />,
    permission: [],
  },
  {
    key: '404',
    path: '/404',
    component: <Error404 />,
  },

  {
    key: 'help-center',
    path: '/help-center',
    component: <HelpCenter />,
    permission: [
      GroupRole.Administrador,
      GroupRole.Supervisor,
      GroupRole.Superadmin,
      GroupRole.Conserje,
      GroupRole.Mayordomo,
      GroupRole.Comite,
    ],
  },
];

export const generateRouteComponent = (inputRoutes: SwappiRouteProps[]) =>
  inputRoutes.map((item) => {
    // if (item.collapse) {
    //   return generateRouteComponent(item.collapse);
    // }

    if (item.path) {
      return <Route path={item.path} element={item.component} key={item.key} />;
    }
    return null;
  });

export default routes;
