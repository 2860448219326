import React from 'react';
import { Box, Drawer } from '@mui/material';
import { useDocumentEditorDrawer } from './store';
import DocumentEditorForm from './Form';

const DocumentEditorDrawner = () => {
  const { isOpen, closeDrawner, document, ticket } = useDocumentEditorDrawer();
  const slugType = document?.type.slug;
  const slugCommunity = document?.community?.slug || ticket?.community?.slug;
  const renderForm = document !== null && slugCommunity;
  return (
    <Drawer sx={{ zIndex: 99999 }} anchor={'right'} open={isOpen} onClose={closeDrawner}>
      <Box role="presentation">
        {renderForm && (
          <DocumentEditorForm
            file={document}
            slugType={slugType}
            community={slugCommunity}
            toggleDrawer={closeDrawner}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default DocumentEditorDrawner;
