/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
// Soft UI Dashboard PRO React components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import MaintenanceView from "../";
import SuiAvatar from "components/SuiAvatar";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SuiBadge from "components/SuiBadge";
import { mobileMaxWidth } from "utils";
import { setActivityModalClosed } from "redux/actions/helper";
import useGetMaintenance from "api/services/maintenances/queries/getMaintenance";
import LoadingView from "components/Loading";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  overflow: "visible",
  bgcolor: "background.paper",
  maxHeight: "60%",
  width: "70%",
  p: 0,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 5,
  overflow: "scroll",
  bgcolor: "background.paper",
  maxHeight: "90%",
  width: "100%",
  p: 0,
};

// Custom styles for ComplexProjectCard
function MaintenanceModal({
  modal = false,
  uuid = undefined,
  createdView = null,
}) {
  const [open, setOpen] = useState(false);

  const { data: maintenance, isLoading } = useGetMaintenance(
    modal ? (open ? uuid : undefined) : uuid
  );

  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    console.log("close");
    dispatch(setActivityModalClosed({ time: new Date() }));
    setOpen(false);
  };
  const { slug } = useParams();

  useEffect(() => {
    if (createdView && uuid) {
      setOpen(true);
    }
  }, [uuid]);

  if (isLoading) return <LoadingView />;{console.log("maintenance", maintenance)}
  return (
    <SuiBox>
      {!modal && (
        <Card style={{ cursor: "pointer" }} onClick={handleOpen}>
          <SuiBox p={2}>
            <SuiBox display="flex" alignItems="center">
              <SuiBox ml={2} lineHeight={0} style={{ width: "100%" }}>
                <SuiBox mb={1} lineHeight={0}>
                  <SuiTypography
                    style={{ width: "100%" }}
                    variant="h6"
                    textTransform="capitalize"
                    fontWeight="medium"
                  >
                    {maintenance?.area?.name}
                    <AiOutlineEye size="2em" style={{ float: "right" }} />
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </SuiBox>
            <Divider />
            {slug ? (
              <SuiBox>
                <SuiBox
                  p={2}
                  width={"100%"}
                  pt={0}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Proveedor:&nbsp;
                  </SuiTypography>
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {maintenance?.service_provider &&
                      maintenance?.service_provider.executive_name}{" "}
                  </SuiTypography>
                </SuiBox>
                <SuiBox
                  p={2}
                  width={"100%"}
                  pt={0}
                  lineHeight={0}
                  display="inline-block"
                >
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Razón Social:&nbsp;
                  </SuiTypography>
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {maintenance?.service_provider?.razon_social}{" "}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            ) : (
              <SuiBox
                p={2}
                width={"100%"}
                pt={0}
                lineHeight={0}
                display="inline-block"
              >
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Comunidad:&nbsp;
                </SuiTypography>
                <SuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {maintenance?.community.name}{" "}
                </SuiTypography>
              </SuiBox>
            )}

            <SuiBox p={2} pt={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Periodicidad:&nbsp;
              </SuiTypography>
              <SuiTypography
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color="text"
              >
                {maintenance?.period}
              </SuiTypography>
            </SuiBox>

            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            ></SuiBox>
          </SuiBox>
        </Card>
      )}
      {modal && !createdView && (
        <SuiBadge
          style={{ cursor: "pointer" }}
          badgeContent={<AiOutlineEye size="16px" />}
          onClick={handleOpen}
          size="xs"
          container
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={""}
      >
        <Box style={window.innerWidth <= mobileMaxWidth ? styleMobile : style}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon style={{ alignSelf: "right" }} />
          </div>
          {maintenance && (
            <MaintenanceView maintenance={maintenance} onDelete={handleClose} />
          )}
        </Box>
      </Modal>
    </SuiBox>
  );
}

export default MaintenanceModal;
