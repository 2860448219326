/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import SuiInput from 'components/SuiInput';
import { useDispatch } from 'react-redux';
import { updateUserInAdmin } from 'redux/actions/users';
import { Checkbox, Switch } from '@mui/material';
import { getPermission } from 'utils';
import { weeklyReportConfig } from 'redux/actions/users';
import { CheckBox } from '@mui/icons-material';

let roles = ['conserje', 'mayordomo'];

function ProfileInfoCard(props) {
  const labels = [];
  const values = [];
  const dispatch = useDispatch();
  const { socialMediaColors } = colors;
  const [first_name, setfirst_name] = useState(props.memberProp.first_name);
  const [phone, setphone] = useState(props.memberProp.phone);
  const [rut, setRut] = useState(props.memberProp.rut);
  const [member, setmember] = useState({});
  const [edit, setedit] = useState(false);
  const [group, setgroup] = useState(
    props.memberProp.groups && props.memberProp.groups[0] && props.memberProp.groups[0].name
  );
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadFile, setuploadFile] = useState(false);
  const [weeklyReport, setweeklyReport] = useState(props.memberProp.weekly_report_notification);
  const [file, setFile] = useState(null);
  const { size } = typography;

  const changeHandler = (event) => {
    setFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  useEffect(() => {
    setmember(props.memberProp);
    console.log(props.memberProp);
    setfirst_name(props.memberProp.first_name);
    setphone(props.memberProp.phone);
    setRut(props.memberProp.rut);
    setgroup(
      props.memberProp.groups && props.memberProp.groups[0] && props.memberProp.groups[0].name
    );
    setweeklyReport(props.memberProp.weekly_report_notification);
  }, [props.memberProp]);

  return (
    <Card>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {props.title}
        </SuiTypography>
        <SuiTypography
          variant="body2"
          color="secondary"
          onClick={() => {
            if (edit) {
              const formData = new FormData();
              formData.append('email', member.email);
              formData.append('first_name', first_name);
              formData.append('phone', phone);
              formData.append('rut', rut);
              if (isFilePicked) {
                formData.append('profile_picture', file);
                setIsFilePicked(false);
              }
              dispatch(updateUserInAdmin(formData));
            }
            setedit(!edit);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Tooltip title={props.action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox opacity={0.3}>
          <Divider />
        </SuiBox>
        <SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Nombre: &nbsp;{' '}
              {edit && (
                <SuiInput value={first_name} onChange={(e) => setfirst_name(e.target.value)} />
              )}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp;
              {!edit && member.first_name}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Teléfono: &nbsp;
              {edit && <SuiInput value={phone} onChange={(e) => setphone(e.target.value)} />}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp;
              {!edit && member.phone}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Email: &nbsp;
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{member.email}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" py={1} pr={2}>
            <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Rut: &nbsp;
              {edit && <SuiInput value={rut} onChange={(e) => setRut(e.target.value)} />}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              &nbsp;
              {!edit && member.rut}
            </SuiTypography>
          </SuiBox>
          {getPermission(['superadmin']) && (
            <SuiBox display="flex" py={1} pr={2}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Reporte semanal: &nbsp;
              </SuiTypography>
              <Switch
                checked={weeklyReport}
                onChange={(e) => {
                  dispatch(
                    weeklyReportConfig({
                      emails: [member.email],
                    })
                  );
                }}
              />
            </SuiBox>
          )}
          {edit && (
            <SuiBox p={2} pl={0}>
              <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Foto de perfil: &nbsp;
              </SuiTypography>
              <br />
              <SuiInput type="file" onChange={(e) => changeHandler(e)} />
            </SuiBox>
          )}
          {edit && roles.includes(group) && (
            <SuiBox display="flex" py={1} pr={2}>
              <SuiBox display="flex" py={1} pr={2}>
                <Checkbox
                  checked={group === 'conserje'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setgroup('conserje');
                    } else {
                      setgroup('mayordomo');
                    }
                  }}
                />
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Conserje
                </SuiTypography>
              </SuiBox>
              <SuiBox display="flex" py={1} pr={2}>
                <Checkbox
                  checked={group === 'mayordomo'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setgroup('mayordomo');
                    } else {
                      setgroup('conserje');
                    }
                  }}
                />
                <SuiTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  Mayordomo
                </SuiTypography>
              </SuiBox>
            </SuiBox>
          )}

          {edit && (
            <SuiBox display="flex" pb={3} pr={2}>
              <SuiButton
                style={{ marginTop: 15, float: 'right' }}
                color="warning"
                variant="gradient"
                size="small"
                onClick={() => {
                  if (edit) {
                    const formData = new FormData();
                    formData.append('email', member.email);
                    formData.append('first_name', first_name);
                    formData.append('phone', phone);
                    formData.append('rut', rut);
                    if (roles.includes(group)) formData.append('group', group);
                    if (isFilePicked) {
                      formData.append('profile_picture', file);
                      setIsFilePicked(false);
                    }
                    dispatch(updateUserInAdmin(formData));
                  }
                }}
              >
                Guardar
              </SuiButton>
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default ProfileInfoCard;
