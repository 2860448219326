import React from 'react';
import { FileDownload } from '@mui/icons-material';
import { useExportCenterStore } from '../store';
import { useExportCenterPdf } from 'api/services/analytics/mutations';
import { SuiButtonTs } from 'components/SuiButton';

const DownloadButton = () => {
  const {
    tickets,
    maintenances,
    visits: visitsReports,
    documents,
    observationsMaintenances,
    observationsGeneral,
  } = useExportCenterStore();
  const { mutate } = useExportCenterPdf();

  const handleDownload = () => {
    mutate({
      tickets,
      maintenances,
      reports: visitsReports,
      documents,
      maintenance_observations: observationsMaintenances,
      general_observations: observationsGeneral,
    });
  };
  return (
    <SuiButtonTs
      variant="gradient"
      color="success"
      size="small"
      endIcon={<FileDownload />}
      onClick={handleDownload}
    >
      Descargar
    </SuiButtonTs>
  );
};

export default DownloadButton;
