import { Box, Typography } from '@mui/material';
import SuiDatePicker from 'components/SuiDatePicker';
import React, { FC } from 'react';
import { Control, useController, UseControllerProps } from 'react-hook-form';

interface InputProps extends UseControllerProps {
  label?: string;
  control: Control<any>;
  size?: 'small' | 'medium';
  options?: Partial<{
    minDate: 'today' | Date;
    maxDate: 'today' | Date;
    enableTime: boolean;
    dateFormat: 'Y-m-d';
  }>;
}

const DatePickerControlled: FC<InputProps> = ({
  name,
  control,
  rules,
  label,
  options,
  disabled,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <Box>
      {label && (
        <Typography variant="label" fontWeight="medium">
          {label}
        </Typography>
      )}
      <SuiDatePicker
        {...field}
        error={!!error}
        options={{
          ...options,
        }}
        input={{ disabled }}
      />
      <Typography variant="h6" fontWeight="light" color={'error.main'}>
        {error?.message}
      </Typography>
    </Box>
  );
};

export default DatePickerControlled;
