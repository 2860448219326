/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React example components
import ProfileInfoCard from './components/ProfileInfoCard';
import SimpleBlogCard from 'examples/Cards/BlogCards/SimpleBlogCard';
import { useState, useEffect } from 'react';
import AssignCommunity from '../AssignCommunity/Modal';
// Data
import UserTicketList from './components/UserTicketList';
import Tickets from 'layouts/pages/tickets/AdminTickets';
import SuiButton from 'components/SuiButton';
import { useSelector, useDispatch } from 'react-redux';
import { deactiveUser } from 'redux/actions/users';
import Swal from 'sweetalert2';
import { isPermissionEnabled } from 'utils';
import { getUsersCommunities } from 'redux/actions/users';
import { getAuthUserProfile } from 'stores/authentication/helpers';
import Documents from 'layouts/pages/documents';

function Overview({ memberProp, community }) {
  const dispatch = useDispatch();
  const [member, setmember] = useState({});
  const [communities, setcommunities] = useState([]);

  useEffect(() => {
    setmember(memberProp);
    dispatch(getUsersCommunities({ email: memberProp.email }));
  }, [memberProp]);

  const userCommunities = useSelector((state) => state.users.getUsersCommunities);

  useEffect(() => {
    if (userCommunities) {
      if (userCommunities.data) {
        setcommunities(userCommunities.data);
      }
    }
  }, [userCommunities]);

  return (
    <SuiBox p={3} pr={0} width="100%">
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <ProfileInfoCard
              title="Información del perfil"
              memberProp={member}
              social={[]}
              action={{ route: '', tooltip: '' }}
            />
            {community && isPermissionEnabled('concierge-deleteConcierge') && (
              <SuiButton
                style={{ marginTop: 15, float: 'right' }}
                color="error"
                size="small"
                variant="gradient"
                onClick={() =>
                  Swal.fire({
                    title: '¿Quieres eliminar este usuario?',
                    showDenyButton: true,
                    confirmButtonText: 'Si',
                    denyButtonText: `No`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      let profile = getAuthUserProfile();
                      dispatch(deactiveUser({ email: member.email }));
                      Swal.fire('Eliminado', '', 'success');
                    } else if (result.isDenied) {
                    }
                  })
                }
              >
                Eliminar
              </SuiButton>
            )}
            {!community && isPermissionEnabled('users-deleteUser') && (
              <SuiButton
                style={{ marginTop: 15, float: 'right' }}
                color="error"
                size="small"
                variant="gradient"
                onClick={() =>
                  Swal.fire({
                    title: '¿Quieres eliminar este usuario?',
                    showDenyButton: true,
                    confirmButtonText: 'Si',
                    denyButtonText: `No`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      let profile = JSON.parse(localStorage.getItem('profile'));
                      dispatch(deactiveUser({ email: member.email }));
                      Swal.fire('Eliminado', '', 'success');
                    } else if (result.isDenied) {
                    }
                  })
                }
              >
                Eliminar
              </SuiButton>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {!community && (
              <SuiBox mb={3}>
                <Card>
                  <SuiBox pt={2} px={2}>
                    <SuiBox mb={0.5}>
                      <SuiTypography variant="h4" fontWeight="medium">
                        Comunidades
                      </SuiTypography>
                      {isPermissionEnabled('communities-assignCommunity') && (
                        <AssignCommunity member={member} />
                      )}
                    </SuiBox>
                    <SuiBox mb={1}>
                      <SuiTypography variant="button" fontWeight="regular" color="text">
                        Comunidades asociadas al colaborador
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                  <SuiBox p={2}>
                    <Grid container spacing={3}>
                      {communities
                        ? communities.map((community, i) => (
                            <Grid item xs={12} md={6} xl={3} key={i}>
                              <SimpleBlogCard
                                image={community.picture}
                                community={community}
                                title={community.name}
                                description=""
                                action={{
                                  type: 'internal',
                                  route: '/communities/' + community.slug,
                                  color: 'info',
                                  label: 'Ver',
                                }}
                              />
                            </Grid>
                          ))
                        : ''}
                    </Grid>
                  </SuiBox>
                </Card>
              </SuiBox>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Tickets member={member.email} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Documents user={member} />
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
}

export default Overview;
