/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React example components
import Footer from 'examples/Footer';
import EventCalendar from 'examples/Calendar';
import SuiTypography from 'components/SuiTypography';
// Calendar application components
import Header from 'layouts/pages/profile/components/Header';
import { useNavigate, useParams } from 'react-router-dom';
// Data
import { getVisitsByCommunity } from 'redux/actions/communities';
import { getVisitsByManagement } from 'redux/actions/communities';
import CreateVisit from './CreateVisit';
import VisitsModal from './VisitView';
import SuiButton from 'components/SuiButton';
import { createVisitReport } from 'redux/actions/reports';
import { isPermissionEnabled } from 'utils';
import LoadingView from 'components/Loading';
import { useAuthenticationStore } from 'stores/authentication';

const parseVisits = (visits, slug) => {
  let profile = useAuthenticationStore.getState().userProfile;
  /* filter visits by community in profile.communities by slug */
  let filteredVisits = visits.filter((visit) => {
    return profile.communities.some((community) => {
      return community.slug === visit.community;
    });
  });
  filteredVisits.map((visit) => {
    if (!slug)
      visit.title =
        visit.title +
        ' (' +
        visit.community.charAt(0).toUpperCase() +
        visit.community.slice(1) +
        ')';
  });
  return filteredVisits;
};

function Calendar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const visitsCommunityResponse = useSelector((state) => state.communities.getVisitsByCommunity);
  const visitsManagementResponse = useSelector((state) => state.communities.getVisitsByManagement);
  const deleteVisitResponse = useSelector((state) => state.communities.deleteVisit);
  const createMaintenanceVisitResponse = useSelector(
    (state) => state.maintenance.createMaintenanceVisit
  );
  const [loading, setloading] = useState(false);
  const createVisitReportResponse = useSelector((state) => state.communities.createVisitReport);
  const markAsVisitedResponse = useSelector((state) => state.communities.markAsVisited);
  const [visits, setvisits] = useState([]);
  const [visit, setvisit] = useState(null);
  const editVisitResponse = useSelector((state) => state.communities.editVisit);
  let { slug } = useParams();
  useEffect(() => {
    if (!slug) {
      let profile = useAuthenticationStore.getState().userProfile;
      dispatch(getVisitsByManagement(profile.management.slug));
    } else {
      dispatch(getVisitsByCommunity(slug));
    }
  }, []);

  useEffect(() => {
    if (markAsVisitedResponse.data) {
      if (!slug) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getVisitsByManagement(profile.management.slug));
      } else {
        dispatch(getVisitsByCommunity(slug));
      }
    }
  }, [markAsVisitedResponse]);

  useEffect(() => {
    if (editVisitResponse.data) {
      if (!slug) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getVisitsByManagement(profile.management.slug));
      } else {
        dispatch(getVisitsByCommunity(slug));
      }
    }
  }, [editVisitResponse]);

  useEffect(() => {
    if (deleteVisitResponse.data) {
      if (!slug) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getVisitsByManagement(profile.management.slug));
      } else {
        dispatch(getVisitsByCommunity(slug));
      }
    }
  }, [deleteVisitResponse]);

  useEffect(() => {
    if (createMaintenanceVisitResponse.data) {
      if (!slug) {
        let profile = useAuthenticationStore.getState().userProfile;
        dispatch(getVisitsByManagement(profile.management.slug));
      } else {
        dispatch(getVisitsByCommunity(slug));
      }
    }
  }, [createMaintenanceVisitResponse]);

  useEffect(() => {
    if (visitsCommunityResponse.data !== undefined) {
      setvisits(parseVisits(visitsCommunityResponse.data, slug));
      setloading(false);
    }
  }, [visitsCommunityResponse]);

  useEffect(() => {
    if (visitsManagementResponse.data !== undefined) {
      setvisits(parseVisits(visitsManagementResponse.data, slug));
      setloading(false);
    }
  }, [visitsManagementResponse]);

  return (
    <SuiBox pt={3}>
      <SuiTypography variant="h4" fontWeight="medium" mt={1} textAlign="center">
        Calendario
      </SuiTypography>
      {isPermissionEnabled('visits-createVisit') && (
        <SuiBox display="flex" justifyContent="flex-end" mt={1} mb={4} mx={2}>
          <CreateVisit />
        </SuiBox>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} sx={{ height: 'max-content' }}>
          {useMemo(
            () => (
              <EventCalendar
                eventClick={(e) => {
                  let eventProps = e.event._def.extendedProps;
                  let event = {
                    title: e.event._def.title,
                    ...eventProps,
                    start: e.event._instance.range.start,
                    end: e.event._instance.range.end,
                  };
                  setvisit(event);
                }}
                initialView="dayGridMonth"
                events={visits}
                selectable
                editable
              />
            ),
            [visits]
          )}
        </Grid>
      </Grid>
      <VisitsModal visit={visit} />
    </SuiBox>
  );
}

export default Calendar;
