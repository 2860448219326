/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import DefaultImage from "assets/images/com-default.png";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import { CircularProgress, Tooltip } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import { AiOutlineWarning } from "react-icons/ai";
import { HiFire } from "react-icons/hi";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { updateCommunityImage } from "redux/actions/communities";
import { useDispatch } from "react-redux";
import { getPermission } from "utils";
import { isPermissionEnabled } from "utils";
import useGetCommunityImage from "api/services/communities/queries/getCommunityImage";
function SimpleBlogCard({ title, action, community }) {
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { data: image, isLoading } = useGetCommunityImage(community?.slug);

  return (
    <Card>
      <Link to={action.route}>
        <SuiBox mt={2} mx={2}>
          {isLoading ? (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ margin: "0 auto", objectFit: "cover" }}
              width={"100%"}
              height={"350px"}
              borderRadius="lg"
            >
              <CircularProgress color="info" />
            </SuiBox>
          ) : (
            <SuiBox
              display="block"
              style={{ margin: "0 auto", objectFit: "cover" }}
              component="img"
              src={image?.url}
              alt={title}
              width={"100%"}
              height={"350px"}
              borderRadius="lg"
            />
          )}
        </SuiBox>
      </Link>
      <SuiBox pt={2} pb={3} px={3}>
        <SuiBox
          style={
            !edit ? { justifyContent: "space-between", display: "flex" } : {}
          }
        >
          <SuiTypography
            display="inline"
            variant="h5"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {title}
          </SuiTypography>
          {edit && <br />}
          {edit ? (
            <SuiBox>
              <SuiInput
                type="file"
                onChange={(e) => {
                  const formData = new FormData();
                  formData.append("picture", e.target.files[0]);
                  dispatch(updateCommunityImage(community.slug, formData));
                  setEdit(!edit);
                }}
              />
              <SuiButton
                variant="text"
                color="error"
                size="small"
                onClick={(e) => {
                  setEdit(!edit);
                }}
              >
                {" "}
                Cancelar
              </SuiButton>
            </SuiBox>
          ) : getPermission(["superadmin", "administrador", "supervisor"]) &&
            isPermissionEnabled("communities-editCommunity") ? (
            <SuiButton
              variant="text"
              color="info"
              size="small"
              alignSelf={"flex-end"}
              justifyContent={"right"}
              onClick={(e) => {
                e.preventDefault();
                setEdit(!edit);
              }}
            >
              Editar
            </SuiButton>
          ) : (
            <></>
          )}
        </SuiBox>

        {community && (
          <SuiBox>
            <SuiBox mt={2} mb={3} display="flex">
              <SuiTypography variant="button" color="text">
                Tareas abiertas:{" "}
                {community.tickets_info && community.tickets_info.open}
              </SuiTypography>
              {community.tickets_info &&
                community.tickets_info.has_critic_tickets > 0 && (
                  <Tooltip
                    key={community.slug}
                    title="Tareas críticas"
                    placement="top"
                  >
                    <SuiBox color="error" mt={-1}>
                      <HiFire color="error" />
                    </SuiBox>
                  </Tooltip>
                )}
            </SuiBox>
            <SuiBox display="flex">
              <SuiTypography variant="button" color="text">
                Tareas inactivas:{" "}
                {community.tickets_info && community.tickets_info.inactive}
              </SuiTypography>
              {community.tickets_info && community.tickets_info.inactive > 0 ? (
                <Tooltip
                  key={community.slug + "inactive"}
                  title="Tareas inactivas más de 2 semanas"
                  placement="top"
                >
                  <SuiBox color="error">
                    <AiOutlineWarning color="error" />
                  </SuiBox>
                </Tooltip>
              ) : (
                ""
              )}
            </SuiBox>
          </SuiBox>
        )}

        {action.type === "external" ? (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            <SuiButton color={action.color ? action.color : "dark"}>
              {action.label}
            </SuiButton>
          </MuiLink>
        ) : (
          <Link to={action.route}>
            <SuiButton
              variant="gradient"
              color={action.color ? action.color : "dark"}
            >
              {action.label}
            </SuiButton>
          </Link>
        )}
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBlogCard;
