import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { SelectOptionForm } from 'types/forms';
import { SuiBadgeTs } from 'components/SuiBadge';

interface SelectBadgeProps extends UseControllerProps {
  label?: string;
  isMulti?: boolean;
  control: Control<any>;
  options?: SelectOptionForm[];
}
const SelectBadgeControlled: FC<SelectBadgeProps> = ({
  label,
  isMulti,
  name,
  control,
  rules,
  options = [],
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  const handleSelect = (option: SelectOptionForm) => {
    if (isMulti) {
      const updatedValue = { ...field.value };
      if (updatedValue[option.value]) delete updatedValue[option.value];
      else updatedValue[option.value] = true;
      field.onChange(updatedValue);
    } else {
      field.onChange({ [option.value]: true });
    }
  };

  return (
    <Box>
      {label && (
        <Typography variant="h6" fontWeight="medium" mb={1}>
          {label}
        </Typography>
      )}
      <Box display={'flex'} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
        {options.map((item) => {
          const { value, label } = item;
          return (
            <SuiBadgeTs
              key={value}
              color={field.value[value] ? 'success' : 'light'}
              style={{ cursor: 'pointer' }}
              badgeContent={label}
              variant="gradient"
              onClick={() => handleSelect(item)}
              size="xs"
              circular
              container
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default SelectBadgeControlled;
