/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SuiAvatar
import SuiAvatarRoot from 'components/SuiAvatar/SuiAvatarRoot';
import useGetUserImage from 'api/services/user/queries/getUserImage';

const SuiAvatar = forwardRef(({ bgColor, size, shadow, ...rest }, ref) => (
  <SuiAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

// Setting default values for the props of SuiAvatar
SuiAvatar.defaultProps = {
  bgColor: 'transparent',
  size: 'md',
  shadow: 'none',
};

// Typechecking props for the SuiAvatar
SuiAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    'transparent',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  shadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset']),
};

export const SuiAvatarTs = (props) => {
  const getUserImage = useGetUserImage(props.uuid);
  if (getUserImage.isLoading) {
    return <SuiAvatar {...props} />;
  }else if (getUserImage.isSuccess) {
    return <SuiAvatar {...props} src={getUserImage.data} />
  }
  return <SuiAvatar {...props} />

};
export default SuiAvatar;
