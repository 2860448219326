import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { SuiSelectTs } from 'components/SuiSelect';
import { SelectOptionForm } from 'types/forms';
import { SuiButtonTs } from 'components/SuiButton';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

interface SelectProps extends UseControllerProps {
  control: Control<any>;
  label?: string;
  placeholder?: string;
  options?: SelectOptionForm[];
}

const SelectOrderControlled: FC<SelectProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  options = [],
}) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  const [value, setValue] = useState((field.value as string).replace('-', ''));
  const [order, setOrder] = useState((field.value as string).includes('-') ? '-' : '');
  useEffect(() => {
    field.onChange(`${order}${value}`);
  }, [value, order]);
  return (
    <Box>
      {label && (
        <Typography variant="h6" fontWeight="medium">
          {label}
        </Typography>
      )}
      <Box width={'100%'} display={'flex'} flexDirection={'row'}>
        <Box width={'100%'}>
          <SuiSelectTs
            style={{ width: '100%' }}
            value={value}
            onChange={(e) => setValue(e.value)}
            size={'small'}
            placeholder={placeholder}
            options={options}
          />
        </Box>
        <Box>
          <SuiButtonTs
            variant="icon"
            circular={true}
            onClick={() => setOrder((prev) => (prev === '-' ? '' : '-'))}
          >
            {order === '-' ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
          </SuiButtonTs>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectOrderControlled;
