import eventEmitter from "./EventEmitter";

const useEventEmitter = (event: string) => {
  const emit = (data?: any) => {
    console.log(event, data);
    eventEmitter.publish(event, data);
  };
  return { emit };
};

export default useEventEmitter;
