/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/TabPanel';
// @mui material components
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React example components
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Update from '../Update';

// Reports page components
import Reviews from 'layouts/pages/users/reports/components/Reviews';

import { getProvidersByManagement } from 'redux/actions/providers';
import { useDispatch, useSelector } from 'react-redux';

// Data
import DataTable from 'examples/Tables/DataTable';
import Header from '../../profile/components/Header';
import { Card } from '@mui/material';
import SuiTypography from 'components/SuiTypography';
import { getTicketsByProvider } from 'redux/actions/providers';
import { getMainetenanceByProvider, getDocumentsByProvider } from 'redux/actions/providers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminTickets from 'layouts/pages/tickets/AdminTickets';
import Maintenance from 'layouts/pages/maintenance';
import Documents from 'layouts/pages/documents';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import SimpleBlogCard from 'examples/Cards/BlogCards/SimpleBlogCard';
import AssignCommunityModal from '../AssignCommunity';
import { isPermissionEnabled } from 'utils';
import Contacts from '../Contacts';
import { getProviderByUuid } from 'redux/actions/providers';
import Error404 from 'layouts/authentication/error/404';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useAuthenticationStore } from 'stores/authentication';

const providersTableData = (providers) => {
  let columns = [
    { Header: 'Nombre', accessor: 'executive_name', width: '10%' },
    { Header: 'Email', accessor: 'email', width: '30%' },
    { Header: 'Teléfono', accessor: 'phone', width: '10%' },
    { Header: 'Giro', accessor: 'giro', width: '15%' },
    { Header: 'Rut', accessor: 'rut', width: '20%' },
    { Header: 'Razón Social', accessor: 'razon_social' },
    { Header: 'Acciones', accessor: 'actions' },
  ];
  if (providers.data === undefined) {
    return { columns, rows: [] };
  }
  let rows = providers.data.map((provider, i) => {
    return {
      code: provider.code,
      title: provider.title,
      executive_name: provider.executive_name,
      email: provider.email,
      phone: provider.phone,
      giro: provider.giro,
      rut: provider.rut,
      razon_social: provider.razon_social,
      actions: (
        <div>
          <Update provider={provider} />
        </div>
      ),
    };
  });
  return { columns, rows };
};

function Provider() {
  const { state } = useLocation();
  const { providerUuid } = useParams();
  const dispatch = useDispatch();
  const [columns, setcolumns] = useState([]);
  const [rows, setrows] = useState([]);
  const [error, setError] = useState(false);
  // ComplexStatisticsCard dropdown menu state
  const createProviderResponse = useSelector((state) => state.providers.createProviderByManagement);
  const updateProviderResponse = useSelector((state) => state.providers.updateProvider);
  const getProviderResponse = useSelector((state) => state.providers.getProvider);
  const [communities, setCommunities] = useState([]);
  const [provider, setprovider] = useState({});

  const updateProviderCommunityResponse = useSelector(
    (state) => state.providers.addCommunityToProvider
  );
  // Dropdown menu template for the ComplexProjectCard
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state !== undefined) {
      setCommunities(provider.communities);
    }
  }, [provider]);

  useEffect(() => {
    if (updateProviderCommunityResponse.data) {
      setCommunities(updateProviderCommunityResponse.data.communities);
      provider.communities = updateProviderCommunityResponse.data.communities;
    }
  }, [updateProviderCommunityResponse]);

  useEffect(() => {
    if (createProviderResponse.data !== undefined) {
      let profile = useAuthenticationStore.getState().userProfile;
      dispatch(getProvidersByManagement(profile.management.slug));
    }
  }, [createProviderResponse]);

  useEffect(() => {
    if (updateProviderResponse.data !== undefined) {
      let profile = useAuthenticationStore.getState().userProfile;
      dispatch(getProvidersByManagement(profile.management.slug));
    }
  }, [updateProviderResponse]);

  useEffect(() => {
    if (updateProviderResponse.data) dispatch(getProviderByUuid(providerUuid));
  }, [updateProviderResponse]);

  useEffect(() => {
    dispatch(getProviderByUuid(providerUuid));
  }, [providerUuid]);

  useEffect(() => {
    console.log(getProviderResponse);
    if (getProviderResponse.data !== undefined) {
      setprovider(getProviderResponse.data);
      if (getProviderResponse.status === 404) {
        setError(true);
      }
    }
  }, [getProviderResponse]);

  return (
    <SuiBox>
      <SuiBox p={3}>
        {error && (
          <SuiTypography
            style={{
              minHeight: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            variant="h2"
          >
            Proveedor no encontrado
          </SuiTypography>
        )}
        {!error && (
          <SuiBox mb={3}>
            <Grid container spacing={3}>
              <SuiBox width={'100%'} mt={5} mb={2}>
                <SuiBox display="flex" style={{ justifyContent: 'space-between' }}>
                  <SuiTypography variant="h4" fontWeight="medium" style={{ padding: 15 }}>
                    Proveedor
                  </SuiTypography>
                  <SuiBox>
                    <Update provider={provider} />
                  </SuiBox>
                </SuiBox>

                <Card style={{ width: '100%', padding: 15 }}>
                  <Grid container>
                    <Grid item spacing={3} xs={12} sm={6}>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Nombre:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.executive_name}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Razón Social: &nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.razon_social}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Email:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.email}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Rut:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.rut}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Teléfono:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.phone}
                        </SuiTypography>
                      </Grid>
                    </Grid>
                    <Grid item spacing={3} xs={12} sm={6}>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Número de cuenta:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.account_number}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Rut cuenta: &nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.account_rut}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Banco:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.account_bank}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Tipo de cuenta:&nbsp;
                        </SuiTypography>
                        <SuiTypography variant="button" fontWeight="regular" color="text">
                          {provider.account_type}
                        </SuiTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
                <Contacts contacts={provider.contacts} />
              </SuiBox>
              <SuiBox style={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  <Tab label="Tareas" sx={{ bgColor: 'info' }} />
                  <Tab label="Mantenciones" />
                  <Tab label="Documentos" />
                  <Tab label="Comunidades" />
                </Tabs>
              </SuiBox>
              <TabPanel value={value} index={0}>
                <Card style={{ width: '100%', padding: 15 }}>
                  <AdminTickets />
                </Card>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Card style={{ width: '100%', padding: 15, marginTop: 10 }}>
                  <Maintenance providerUuid={provider.uuid} />
                </Card>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Card style={{ width: '100%', padding: 15, marginTop: 10 }}>
                  <Documents providerUuid={provider.uuid} />
                </Card>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Card style={{ width: '100%', padding: 15, marginTop: 10 }}>
                  <SuiBox style={{ float: 'right' }}>
                    {isPermissionEnabled('communities-assignCommunity') && (
                      <AssignCommunityModal member={provider} />
                    )}
                  </SuiBox>
                  {communities && communities.length > 0 && (
                    <Grid container spacing={3}>
                      {communities.map((community, i) => (
                        <Grid item xs={12} md={6} xl={3} key={i}>
                          <SimpleBlogCard
                            image={community.picture}
                            title={community.name}
                            community={community}
                            description=""
                            action={{
                              type: 'internal',
                              route: '/communities/' + community.slug,
                              color: 'info',
                              label: 'Ver',
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Card>
              </TabPanel>
            </Grid>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
}

export default Provider;
