/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar, { SuiAvatarTs } from "components/SuiAvatar";
import { getPermission } from "utils";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard PRO React data variables
import Create from "./Create";
import { capitalize } from "utils";
import SuiBadge from "components/SuiBadge";
import { isPermissionEnabled } from "utils";

function Members(props) {
  const { borderWidth } = borders;
  const [team, setteam] = useState([props.members]);
  const [selected, setselected] = useState(0);

  function customSort(arr) {
    // Verificar si el arreglo contiene algún objeto con name = "superadmin"
    const hasSuperadmin = arr.some((obj) => {
      if (obj.groups && Array.isArray(obj.groups)) {
        return obj.groups.some((group) => group.name === "superadmin");
      }
      return false;
    });

    // Si no hay "superadmin" en el arreglo, realizar un sort alfabético normal
    if (!hasSuperadmin) {
      return arr.sort((a, b) => a.groups[0].name.localeCompare(b.groups[0].name));
    }

    // Clonar el arreglo para evitar modificar el original
    const sortedArr = [...arr];

    // Realizar el sort personalizado
    sortedArr.sort((a, b) => {
      const aIsSuperadmin = a.groups.some((group) => group.name === "superadmin");
      const bIsSuperadmin = b.groups.some((group) => group.name === "superadmin");

      if (aIsSuperadmin) {
        return -1; // Colocar "superadmin" al principio
      } else if (bIsSuperadmin) {
        return 1; // Colocar "superadmin" al principio
      } else {
        return a.groups[0].name.localeCompare(b.groups[0].name); // Ordenar alfabéticamente por name
      }
    });

    return sortedArr;
  }

  useEffect(() => {
    setteam(customSort(props.members));
  }, [props.members]);

  return (
    <Card sx={{ overflow: "scroll" }}>
      <SuiBox width="100%" pt={3} pb={2.5} px={3}>
        <Grid container wrap="nowrap">
          {
            <Grid item xs={4} sm={3} md={2} lg={1} sx={{ flex: "0 0 100%" }}>
              <Create community={props.community} />
            </Grid>
          }

          {team.length > 0
            ? team.map((member, i) => (
                <Grid key={i} item xs={4} sm={3} md={2} lg={1} sx={{ flex: "0 0 100%" }}>
                  <SuiBox style={{ textAlign: "center" }}>
                    <SuiBox
                      borderRadius="50%"
                      width="3.625rem"
                      height="3.625rem"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      bgColor={
                        i !== selected
                          ? "linear-gradient(310deg, #2152ff, #21d4fd)"
                          : "linear-gradient(310deg, green, #21d4fd) "
                      }
                      sx={{ cursor: "pointer", margin: "0 auto" }}
                      onClick={() => {
                        props.setmember(member);
                        setselected(i);
                      }}
                    >
                      <SuiAvatarTs uuid={member.uuid} src={member.profile_picture} alt={member.first_name} />
                    </SuiBox>
                    {member.groups && (
                      <SuiBadge
                        style={{ margin: "0 auto" }}
                        variant="gradient"
                        color={
                          member.groups[0] && member.groups[0].name == "supervisor"
                            ? "primary"
                            : member.groups[0] && member.groups[0].name == "conserje"
                            ? "warning"
                            : member.groups[0] && member.groups[0].name == "superadmin"
                            ? "success"
                            : "info"
                        }
                        badgeContent={member.groups[0] && capitalize(member.groups[0].name)}
                      />
                    )}
                    <SuiBox mt={0.75} textAlign="center" lineHeight={1}>
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        {member.first_name}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Grid>
              ))
            : ""}
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Members;
