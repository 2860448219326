import TicketModal from 'components/Tickets/TicketDetailModal';

import MaintenanceModal from 'layouts/pages/maintenance/View/MaintenanceCard';
import React from 'react';
import stylesList from './css/list.css';
import { useParams } from 'react-router-dom';
import VisitsModal from 'layouts/pages/calendar/VisitView';
import SuiBadge from 'components/SuiBadge';

function TaskListTableDefault({ rowHeight, rowWidth, tasks, onExpanderClick, fontSize }) {
  const { slug } = useParams();
  const [visit, setVisit] = React.useState(null);
  return (
    <div
      className={'taskListWrapper'}
      style={{
        fontFamily: 'Roboto',
        fontSize: fontSize,
      }}
    >
      <VisitsModal visit={visit} />
      {tasks.map((t) => {
        let expanderSymbol = '';
        if (t.hideChildren === false) {
          expanderSymbol = '▼';
        } else if (t.hideChildren === true) {
          expanderSymbol = '▶';
        }

        return (
          <div className={'taskListTableRow'} style={{ height: rowHeight }} key={`${t.id}row`}>
            <div
              className={'taskListCell'}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
                textAlign: 'center',
              }}
              title={t.name}
            >
              <div className={'taskListNameWrapper'}>
                <div
                  style={{
                    whiteSpace: 'normal',
                    paddingLeft: 3,
                  }}
                >
                  {t.name.length > 44 ? t.name.substring(0, 40) + '...' : t.name}
                </div>
              </div>
            </div>
            {!slug && (
              <div
                className={'taskListCell'}
                style={{
                  minWidth: rowWidth,
                  maxWidth: rowWidth,
                }}
              >
                {t.community.name}
              </div>
            )}
            <div
              className={'taskListCell'}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              {t.start.toLocaleString('es-CL').split(',')[0]}
            </div>
            <div
              className={'taskListCell'}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              {t.end.toLocaleString('es-CL').split(',')[0]}
            </div>
            <div
              className={'taskListCell'}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              {t.isVisit ? (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <MaintenanceModal maintenance={t.maintenance} modal={true} />
                  <SuiBadge
                    container
                    style={{ cursor: 'pointer' }}
                    color="warning"
                    badgeContent="Visita"
                    size="xs"
                    onClick={() => setVisit(t.visit)}
                  />
                </div>
              ) : (
                <TicketModal uuid={t.id} community={t.community} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default TaskListTableDefault;
