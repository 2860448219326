import { Icon, IconButton } from '@mui/material';
import useLogout from 'api/services/auth/mutation/logout';
import SuiBadge from 'components/SuiBadge';
import SuiTypography from 'components/SuiTypography';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDTEs } from 'redux/actions/users';
import { getPermission } from 'utils';
import useMediaScreen from 'utils/Hooks/useMedia';

const HalfScreenErrorBanner = ({ number }) => {
  const [isVisible, setIsVisible] = useState(true);
  const { mutate: logout } = useLogout();

  const { isMobile } = useMediaScreen();
  const dispatch = useDispatch();
  const dtesResponse = useSelector((state) => state.users.getDTEs);
  const [dtes, setDtes] = useState([]);

  useEffect(() => {
    if (dtesResponse) if (dtesResponse.data) setDtes(dtesResponse.data.dtes);
  }, [dtesResponse]);

  useEffect(() => {
    dispatch(getDTEs());
  }, []);

  return (
    <>
      {isMobile && isVisible && (
        <div style={isVisible ? halfScreenErrorBannerStyle : hidddenHalfScreenErrorBannerStyle}>
          <p>
            Tienes {number} factura{number > 1 ? 's' : ''} pendientes de pago, favor contáctanos
            para regularizar tu situación
          </p>
          {getPermission(['superadmin']) &&
            dtes.map((dte) => {
              return (
                <a href={dte.url} target="_blank">
                  Factura Nº {dte.folio} - Fecha de emisión {dte.fecha_emision}
                </a>
              );
            })}
          <SuiBadge
            color="secondary"
            variant="gradient"
            size="xs"
            onClick={logout}
            container
            badgeContent={
              <IconButton size="small">
                <Icon>account_circle</Icon>
                <SuiTypography variant="button" fontWeight="medium" color={true ? 'white' : 'dark'}>
                  Cerrar Sesión
                </SuiTypography>
              </IconButton>
            }
          />
        </div>
      )}
      {!isMobile && (
        <div style={isVisible ? halfScreenErrorBannerStyle : hidddenHalfScreenErrorBannerStyle}>
          <p>
            Tienes {number} factura{number > 1 ? 's' : ''} pendientes de pago, favor contáctanos
            para regularizar tu situación
          </p>
          {getPermission(['superadmin']) &&
            dtes.map((dte) => {
              if (dte.status === 'Documento Vencido')
                return (
                  <div>
                    <a href={dte.url} target="_blank">
                      Factura Nº {dte.folio} - Fecha de emisión {dte.fecha_emision}
                    </a>
                  </div>
                );
            })}
          <SuiBadge
            color="secondary"
            variant="gradient"
            size="xs"
            onClick={logout}
            container
            badgeContent={
              <IconButton size="small">
                <Icon>account_circle</Icon>
                <SuiTypography variant="button" fontWeight="medium" color={true ? 'white' : 'dark'}>
                  Cerrar
                </SuiTypography>
              </IconButton>
            }
          />
        </div>
      )}
    </>
  );
};

const halfScreenErrorBannerStyle = {
  backgroundColor: 'red',
  color: 'white',
  padding: '10px',
  textAlign: 'center',
  position: 'fixed',
  top: '50%', // Coloca el banner en la mitad vertical
  left: '0',
  right: '0',
  transform: 'translateY(-50%)', // Centra verticalmente
  transition: 'opacity 0.5s ease-in-out',
  zIndex: '9999',
};

const hidddenHalfScreenErrorBannerStyle = {
  ...halfScreenErrorBannerStyle,
  opacity: 0,
};

export default HalfScreenErrorBanner;
