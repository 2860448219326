import React, { FC } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useDocumentEditorDrawer } from "components/EditFileDrawer/store";
import { isPermissionEnabled } from "utils";
import Swal from "sweetalert2";
import useDeleteDocument from "api/services/documents/mutations/DeleteDocument";
import { TicketModel } from "api/services/tickets/models";
import { DocumentModel } from "api/services/documents/models";
import { useGetDocumentUrl } from "api/services/documents/queries/getDocumentUrl";
import { TicketQueryHelper } from "api/services/tickets/queries/GetTicket";

interface Props {
  ticket?: TicketModel;
  document: DocumentModel;
}
const DocumentActions: FC<Props> = ({ document, ticket }) => {
  const getDocumentUrl = useGetDocumentUrl(document.uuid, true);
  const { mutate: deleteDocument } = useDeleteDocument();
  const handleDeleteDocument = () => {
    Swal.fire({
      title: "¿Quieres eliminar este documento?",
      showDenyButton: true,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDocument(
          { uuid: document.uuid },
          {
            onSuccess: () => {
              TicketQueryHelper.deleteDocument(document.uuid, ticket.uuid);
            },
          }
        );
      }
    });
  };
  const handleEditClick = () => {
    useDocumentEditorDrawer.setState({
      isOpen: true,
      ticket,
      document,
    });
  };

  const canEditDocument = isPermissionEnabled("documents-editDocument");
  const canDeleteDocument = isPermissionEnabled("documents-deleteDocument");
  return (
    <Box>
      <Tooltip title="Ver" placement="top">
        <a
          href={getDocumentUrl.data?.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "none" }}
        >
          <IconButton>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </a>
      </Tooltip>
      {canEditDocument && (
        <Tooltip title={"Editar documento"} placement="top">
          <IconButton onClick={handleEditClick}>
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {canDeleteDocument && (
        <Tooltip title={"Editar documento"} placement="top">
          <IconButton onClick={handleDeleteDocument}>
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default DocumentActions;
